import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import StylePage from '../../../Style Page/StylePage'
import Form from '../../../Forms/Form'
import Alert from '../../../Alerts/Alert'
import Footer from '../../../Footer/Footer'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import NumberFormat from 'react-number-format'
import { useHistory } from "react-router-dom"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { post_client, get_view_client, put_client, postFlowPackPartner, getFLowPackPartner, delFlowPackPartner, getFlowsPacksPartners } from '../../../Services/client'
import '../../../../Dependencies/dependencies'
import '../../../../Dependencies/jquery-loader'
import InputMaterial from '../../../Forms/InputMaterial';
import TextField from '@material-ui/core/TextField';
import MyModal from '../../../Modal/MyModal'
import { ButtonContainner } from '../../../Button/style';
import { makeStyles } from '@material-ui/core/styles';
import { ListFlowPackPartner } from './ListFlowPackPartner'


export default function Client(props) {

    let history = useHistory();
    const selectFlowPacksRef = useRef(null)
    const [ loading, setLoading ] = useState(false)
    const [ code, setCode ] = useState('')
    const [ name, setName ] = useState('')
    const [ landLine, setLandLine ] = useState('')
    const [ mobilePhone, setMobilePhone ] = useState('')
    const [ error, setError ] = useState([])
    const [ success, setSuccess ] = useState('')
    const [ alert, setAlert ] = useState('')
    const [ flowPacks, setFlowPacks ] = useState({
        options:[],
        getOptionLabel: (option) => ''

    })
    const [ display, setDisplay ] = useState('block')
    const [ dataFlowPacks, setDataFlowPacks ] = useState([])
    const [ successRelation, setSuccessRelation ] = useState('')
    const [ alertRelation, setAlertRelation ] = useState('')
    const [ pesquisa, setPesquisa ] = useState('')
    const [ flowPacksPartners, setFlowPacksPartners ] = useState([])
    const [ flows, setFlows ] = useState([])
    const [ page, setPage ] = useState(1)
    const [ qtdPerPage, setQtdPerPage ] = useState(9)
    const [ order, setOrder ] = useState('asc')
    const [ th, setTh ] = useState('flow_pack.name')
    const [ modal, setModal ] = useState(false);
    const [ idFlowPack, setIdFlowPack ] = useState('')
    
    useEffect(() => {
        viewPerId()
       
    }, [props.match.params.id])

    useEffect(() => {
        getFlowPacksPartnersAll();
    }, [props.match.params.id])
    
    useEffect(() => {
        getFlowPacksAll()
    }, [])


    function viewPerId(){
        if(props.match.params.action){
            get_view_client(props.match.params.id)
            .then(resp => {
                setName(resp.data.name)
                setCode(resp.data.code)
                setLandLine(resp.data.landLine)
                setMobilePhone(resp.data.mobilePhone)

            })
        }
        
    }

    function getFlowPacksAll(){
        getFLowPackPartner()
        .then(resp => setFlowPacks({
            options: resp.data,
            getOptionLabel: options => options.name
        }))
    }
    function getFlowPacksPartnersAll(){
        let idPartner = props.match.params.id
        props.match.params.action &&
        getFlowsPacksPartners(page,qtdPerPage,order,th,pesquisa,idPartner)
        .then(resp => {
                setFlowPacksPartners(resp.data.flowPacksPartners)
                setFlows(resp.data.flows)
            }
        )
    }

    const changeName = event => {
        const name = event.target.value
        setName(name)
    }

    const changeLandLine = event => {
        const number = event.target.value.replace('(','').replace(')','').replace(' ','').replace('-','')
        setLandLine(number)
    }

    const changeMobilePhone = event => {
        const number = event.target.value.replace('(','').replace(')','').replace(' ','').replace('-','')
        setMobilePhone(number)
    }

    const handleForm=(e)=>{
        e.preventDefault();
        setLoading(true)
        
        const data = {
            name: name,
            code: parseInt(code),
            mobilePhone: mobilePhone,
            landLine: landLine,
        }

        if(props.match.params.action === 'edit'){
            const id = props.match.params.id
            put_client(id,data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })              


        }else{
            post_client(data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })              

        }
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            '& label.Mui-focused': {
                color: '#BEBEBE',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#FFFF',
                color: '#FFFF'
            },
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.42)'
            },
            '& .MuiInputBase-input':{
                opacity: '0.42',
                borderBottom: '1px solid #000000'
            },
            '& .MuiFormLabel-root':{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '19px',
                letterSpacing: '0.15px',
                color: 'rgba(0, 0, 0, 0.54)',
            },
            margin: theme.spacing(1),
            width: '100%'
        },
        titleHeader: {
            fontFamily: 'SF Pro Text',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '42px',
            lineHeight: '50px',
            textAlign: 'center',
            color: '#1C68AD',
        }
    }));

    const onChangeModal = (idFlowPack) => {
        setModal(true);
        setIdFlowPack(idFlowPack);
    }

    const classes = useStyles();

    const handleRelations = e => {
        e.preventDefault();
        setLoading(true)
        
        let dataFlowsPacks = [];
        dataFlowPacks.map(desc => dataFlowsPacks.push(desc.id))
        const data = {
            idPartner: parseInt(props.match.params.id),
            flowPacks: dataFlowsPacks
        }

        postFlowPackPartner(data)
        .then(resp=> {
            setLoading(false)
            setAlertRelation('')
            setSuccessRelation(resp.data.success) 
            getFlowPacksPartnersAll()   
        })
        .then(resp => setTimeout(() => {
            setDataFlowPacks([])
            setSuccessRelation('')  
        },2500))
        .catch(e => {
            setLoading(false)
            setSuccessRelation('')
            setAlertRelation(e.response.data.error)  
        })       

    }

    const handleCloseExcluir = event => {
        setModal(false);
        setIdFlowPack('');
        
    }

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false)
        
        delFlowPackPartner(props.match.params.id,idFlowPack)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
        
        })
        .then(resp => (
            setTimeout(()=> {
                setSuccess('')
            },4000)

        ))
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (getFlowPacksPartnersAll()))
        // .then(resp => {setState({success:resp.data.success, error:''})})

    }

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage estilo={{background: '#FFFFFF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '17px'}}>
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('')}
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ):('')}
                <MyModal
                    show={modal}
                    onHide={handleCloseExcluir}
                    title="Excluir Pacote de Fluxo"
                    content="Tem certeza que deseja excluir o pacote de fluxo do parceiro ?"
                    cancel={handleCloseExcluir}
                    del={handleExcluir}     
                    to="#/"
                    type="danger"
                    textButton="Excluir"                   
                />
                <div style={{display: loading === true ? ('none'):('block')}}>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className={classes.titleHeader} style={{ textAlign: 'center'}}>{props.match.params.action === 'edit' ? "Edição de Parceiros" : props.match.params.action === 'view' ? "Visualização de Parceiros" : "Cadastro de Parceiros"}</h2>
                                </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>
                    <Form onSubmit={handleForm}>
                        <div className="row">
                            <div className="col-md-6" style={{display: !props.match.params.action ? 'none' : 'block'}}>
                                <InputMaterial 
                                    label="Código" 
                                    name="codePartner" 
                                    placeholder="Código"
                                    onChange={(e)=>setCode(parseInt(e.target.value))} 
                                    value={code}
                                    disabled={true}
                                    type="text" 
                                    // required={true} 
                                />
                                {error ? (<p style={{color:'red'}}>{error.code}</p>):('')}
                            </div>
                            <div className={`col-md-${!props.match.params.action ? '12' : '6'}`}>
                                <InputMaterial 
                                    label="Nome Completo" 
                                    value={name} 
                                    onChange={changeName} 
                                    id="nameClient"
                                    name="nameClient"
                                    type="text" 
                                    placeholder="Digite o nome do parceiro" 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                 {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <NumberFormat 
                                    disabled={props.match.params.action === 'view' ? (true):(false)} 
                                    allowEmptyFormatting={false} 
                                    label="Telefone Fixo"  
                                    mask='' 
                                    required={true}
                                    customInput={InputMaterial} 
                                    onChange={changeLandLine}  
                                    format={"(##) ####-####"} 
                                    value={landLine}
                                    name="landLine" 
                                    id="landLine"
                                    type="text" 
                                    placeholder="Digite o telefone fixo do parceiro" 
                                />
                                {error ? (<p style={{color:'red'}}>{error.landLine}</p>):('')} 
                            </div>
                            <div className="col-md-6">
                                <NumberFormat 
                                    disabled={props.match.params.action === 'view' ? (true):(false)} 
                                    allowEmptyFormatting={false} 
                                    label="Telefone Celular"  
                                    mask='' 
                                    customInput={InputMaterial} 
                                    onChange={changeMobilePhone}  
                                    format={"(##) #####-####"} 
                                    value={mobilePhone}
                                    name="mobilePhone" 
                                    id="mobilePhone"
                                    type="text" 
                                    placeholder="Digite o telefone celular do parceiro" 
                                />
                                {error ? (<p style={{color:'red'}}>{error.mobilePhone}</p>):('')} 
                            </div>
                        </div>
                        <br></br>
                        <div className="text-right">
                            <button style={{marginRight:'10px'}} type="button" className="btn-danger" style={{marginBottom:'10px', width: '150px', height:'36px', border: 'none', borderColor: '#bd2130', borderRadius: '4px', textTransform: 'uppercase', boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)'}} onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                 <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button onClick={(e) => {props.history.push(`/client/${props.match.params.id}/edit`);e.preventDefault()}} className="btn-primary" style={{marginLeft:'10px', backgroundColor: '#007DFF', width: '150px', height:'36px', border: 'none', borderColor: '#bd2130', borderRadius: '4px', textTransform:'uppercase'}}>Editar</button>
                                    </ButtonContainner>
                                </div>
                            ) : (
                                <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button disabled={props.match.params.action === 'view' ? true : false} type="submit" className="btn-primary" style={{marginLeft:'10px', backgroundColor: '#007DFF', width: '150px', height:'36px', border: 'none', borderColor: '#bd2130', borderRadius: '4px', textTransform:'uppercase'}} >Cadastrar</button>                           
                                    </ButtonContainner>
                                </div>
                            )}
                        </div>
                    </Form>
                    {props.match.params.action &&
                        <React.Fragment>

                        <div className="card" style={{background: 'linear-gradient(180deg,#1D3A59 0%,#0A1D3A 100%)', width: '100%'}}>
                            <a style={{background: 'linear-gradient(180deg,#1D3A59 0%,#0A1D3A 100%)', border: 0, color: '#FFFF'}} onClick={(e)=>setDisplay(`${display === 'none' ? 'block' : 'none'}`)} className="nav-link" className="btn btn-light">Pacote de Fluxos <i className={`fas fa-${display === 'none' ?('angle-double-down'):('angle-double-up')}`}></i></a>
                        </div>

                            <div style={{display: display}}>

                                {/* <hr color="#4b545c"></hr> */}
                                {alertRelation ? (
                                    <Alert type="danger">
                                        {alertRelation}
                                    </Alert>
                                ):('')}
                                {successRelation ? (
                                    <Alert type="success">
                                        {successRelation}
                                    </Alert>
                                ):('')}
                                {props.match.params.action === 'edit' &&
                                    <Form onSubmit={handleRelations} autoComplete="false">
                                        <section className="content-header">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h2 style={{color: '#00ABFF', textAlign: 'center'}}>Adicionar Pacote de Fluxo</h2>
                                                    </div>
                                                </div>
                                            </div>{/* /.container-fluid */}
                                        </section>
                                        <div className="row">
                                            <div className="col-md-11">
                                                <Autocomplete
                                                    {...flowPacks}
                                                    multiple
                                                    id="flowPacksPartners"
                                                    name="flowPacksPartners"
                                                    value={dataFlowPacks}
                                                    onChange={(event, newValue) => {
                                                        setDataFlowPacks(newValue);
                                                    }}
                                                    ref={selectFlowPacksRef}
                                                    disabled={props.match.params.action === 'view' ? true : false}
                                                    renderInput={(params)  => <TextField {...params} className={classes.margin} label="Pacotes de Fluxos" />}
                                                />
                                                {
                                                    dataFlowPacks ? 
                                                        dataFlowPacks.length <= 0 ? (
                                                            <input
                                                                tabIndex={-1}
                                                                autoComplete="off"
                                                                style={{
                                                                    opacity: 0,
                                                                    width: "100%",
                                                                    height: 0,
                                                                    position: "absolute"
                                                                }}
                                                                defaultValue={dataFlowPacks}
                                                                onFocus={() => selectFlowPacksRef.current.focus()}
                                                                required={true}
                                                            />
                                                        ):''
                                                    : dataFlowPacks == null ? (
                                                        <input
                                                            tabIndex={-1}
                                                            autoComplete="off"
                                                            style={{
                                                                opacity: 0,
                                                                width: "100%",
                                                                height: 0,
                                                                position: "absolute"
                                                            }}
                                                            defaultValue={dataFlowPacks}
                                                            onFocus={() => selectFlowPacksRef.current.focus()}
                                                        required={true}
                                                        />
                                                    ):''
                                                }
                                            </div>
                                            <div className="col-md-1">
                                                <div className="input-group mb-3 ml-3" style={{paddingTop:'23px'}}>
                                                    <button type="submit" className="btn btn-primary"><span className="fas fa-plus"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                }
                                <ListFlowPackPartner flows={flows} clearPesquisa={(e)=>setPesquisa('')} pesquisa={pesquisa} qtd_per_page={qtdPerPage} onChange={(e) => setQtdPerPage(e.target.value)} changePesquisa={(e) => setPesquisa(e.target.value)} flowPacks={flowPacksPartners} history={props.history} onDelete={onChangeModal} action={props.match.params.action} />
                            </div>
                        </React.Fragment>
                    }
                </div>   
            </StylePage>
            <Footer />
        </div>
    )
}
