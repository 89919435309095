import styled from "styled-components";

export const ButtonContainner = styled.div`
    button{
        background: #0057B2;
        display: 'inline-block';
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        width: 116px;
        height: 36px;
    }
`;

export const ButtonSaveContainner = styled.div`
    button{
        background: '#F2994A';

        /* background: linear-gradient(180deg, #00E6FF 0%, #0070BA 109.18%); */
        display: 'inline-block';
    }
`;

export const ButtonAction = styled.div`
    button{
        background: '#262D40';
        @media(min-with: 768px){
            display: 'block';
        }
        @media(max-with: 768px){
            display: 'block';
        }
    }
    button :hover{
        background: '#262D40';
    }
`;