import React from 'react'
import { Link } from 'react-router-dom'
import "react-toggle/style.css"
import Card from '../../../Card/Card'
import { ButtonAction } from '../../../Button/style'
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard'


export default function Topics(props) {
    const topic = props.topics || []
    const text = {
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#A1AFC3'
    }
    const button = {
        fontSize: '10px',
        lineHeight: '12px',
        color: '#1C68AD',
        marginRight: '10px', 
        marginTop: '3px', 
        background: 'rgba(28, 104, 173, 0.26)', 
        width: '80px', 
        padding: '0 0 0 2px', 
        height: '20px', 
        border: 'none'
    }
    return topic.map(desc=>(

        <div key={desc.id}  className="col-md-5 col-lg-4 col-sm-4 mt-4" style={{border: 'none'}}>
            <RecipeReviewCard
                title={desc.name}
                actionRoute={props.action}
                actions={
                    props.action !== 'view' &&
                    <div className="text-center">
                        <ButtonAction>
                            <button type="button" onClick={(e) => props.history.push(`/topic/${desc.id}/view`)} style={{...text, ...button}}>Detalhes
                                <i style={{color: '#1C68AD', width:'20px'}} className='fas fa-eye'></i>
                            </button>
                            <button type="button" onClick={(e) => props.history.push(`/topic/${desc.id}/edit`)} style={{...text, ...button, color: '#0D832E', background: 'rgba(13, 131, 46, 0.26)', width: '70px'}}>Editar
                                <i style={{color: '#0D832E', width:'20px'}} className='fas fa-edit'></i>
                            </button>
                            <button type="button" onClick={()=>props.onDelete(desc.id)} style={{...text, ...button, color: '#F11717', background: 'rgba(241, 23, 23, 0.26)', width: '75px'}}>Excluir
                                <i style={{color: '#F11717', width:'20px'}} className='fas fa-trash-alt'></i>
                            </button>
                        </ButtonAction>

                    </div>
                
                }
                rel={false}
            >
                <p style={{...text}}>
                    <span style={{...text, color:'black' }}>Fluxo:</span>
                    {` ${desc.flow}`}
                </p>   
            </RecipeReviewCard>
        </div>


        // <tr key={desc.id}>

            
        //     <td>{desc.flow}</td>
        //     <td>{desc.name}</td>
        //     <td>
        //         <ul className="nav">
        //             <li className="nav-item dropdown">
        //                 <a className="nav-link" data-toggle="dropdown" href="#/">
        //                     <i className="fas fa-ellipsis-h" />             
        //                 </a>
        //                 <div className='dropdown-menu dropdown-menu-right'>                  
        //                     <Link to={`/topic/${desc.id}/view`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link>
                           
        //                     <Link to={`/topic/${desc.id}/edit`} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>
        //                     <Link to="#/" className='dropdown-item' onClick={()=>onDelete(desc.id)} ><i className='fas fa-trash-alt'></i> Excluir</Link>                                
                              
                            
        //                 </div>
        //             </li>
        //         </ul>		
        //     </td>                
        // </tr>
    ))
    
}
