import React from 'react'
import NavItem from './NavItem'
import NavItemDropdown from './NavItemDropdown'
import { useSelector } from 'react-redux'
import IconInicio from '../Imagens/inicio_icon_menu.png'
import IconProducer from '../Imagens/producer_icon_menu.png'
import IconFarm from '../Imagens/farm_icon_menu.png'


export default function Menu(props) {
    const perfis = useSelector( state => state.auth.user.perfis )
    // const id_user = useSelector( state => state.auth.user.id )
    const title = {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '30px',
        letterSpacing: '0.15px',
        color: '#91A5D9',
        marginLeft: '7px'
    };
    
    return (
        <React.Fragment style={{color: '#fff'}}>
            <NavItem to="/index" item="Início" icone={IconInicio} style={{paddingLeft: '2px'}}/>
            <div className="mt-3" style={{borderBottom: "1px solid #fff"}}></div>
            <a className="nav-link">
                <i className='fa fa-arrow-right' style={{color: '#91A5D9' }}>
                    <p style={{...title}}>Controle  Administrativo</p>
                </i>
            </a>
            <NavItem to="/users" item="Usuários" icon="fas fa-users" />
            <NavItem to="/customers" item="Parceiros" icon="fas fa-hands-helping" />
            <NavItem to="/producers" item="Produtores" icone={IconProducer} />
            <NavItem to="/farms" item="Fazendas" icone={IconFarm} />
            <div className="mt-3" style={{borderBottom: "1px solid #fff"}}></div>
            <a className="nav-link">
                <i className='fa fa-arrow-right' style={{color: '#91A5D9' }}>
                    <p style={{...title}}>Fluxos e Tópicos</p>
                </i>
            </a>
            <NavItem item="Pacote de Fluxos" to="/flow_packs" icon="fas fa-cubes" />
            <NavItem item="Fluxos" to="/flows" icon="fas fa-sitemap" />
            <NavItem item="Tópicos" to="/topics" icon="fas fa-list-ul" />
            {/* <NavItem item="Linhas" to="/lines" icon="fas fa-bezier-curve" /> */}
            <div className="mt-3" style={{borderBottom: "1px solid #fff"}}></div>
            <a className="nav-link">
                <i className='fa fa-arrow-right' style={{color: '#91A5D9' }}>
                    <p style={{...title}}>Questões</p>
                </i>
            </a>
            <NavItem to="/questions" item="Perguntas" icon="fa fa-question-circle" />
            <NavItem to="/evaluations" item="Avaliações" icon="fa fa-clipboard-list" />
            {/* <NavItem to="/actions" item="Ações" icon="fas fa-location-arrow" /> */}
           
            {/* <NavItem to="/projects" item="Projetos" icon="fas fa-file-signature" />
            <NavItem item="Reembolsos" to="/refunds" icon="fas fa-hand-holding-usd" />
            <NavItem item="Reembolso Compartilhado" to="/refunds_shared" icon="fas fa-funnel-dollar" />        */}
        </React.Fragment>

    )
    
}

