import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import StylePage from '../../../Style Page/StylePage'
import Form from '../../../Forms/Form'
import Input from '../../../Forms/Input'
import Alert from '../../../Alerts/Alert'
import Footer from '../../../Footer/Footer'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import Select from 'react-select'

import { Link, useHistory } from "react-router-dom"
import { useSelector } from 'react-redux'
import { post_flow, get_view_flow, put_flow } from '../../../Services/flow'
import { colourOptions } from './Docs/colors'
import '../../../../Dependencies/dependencies'
import '../../../../Dependencies/jquery-loader'
import chroma from 'chroma-js';
import { uniqueId } from 'lodash'
import filesize from 'filesize'
import { ContentUpload } from '../../../Upload/styles'
import Upload from '../../../Upload/upload'
import FileList from '../../../FileList/FileList'
import { ButtonContainner } from '../../../Button/style'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
  } from '@material-ui/core/styles';


export default function Flow(props) {

    let history = useHistory();
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState([])
    const [ success, setSuccess ] = useState('')
    const [ alert, setAlert ] = useState('')
    const [ name, setName ] = useState('')
    const [ uploadedFiles, setUploadedFiles ] = useState([])
    const [ colours, setColours ] = useState([])
    const perfis = useSelector(state => state.auth.user.perfis)
    const selectColorRef = useRef(null);
    useEffect(() => {
        viewPerId()
       
    }, [props.match.params.id])

    function viewPerId(){
        if(props.match.params.action){
            get_view_flow(props.match.params.id)
            .then(resp => {
                setName(resp.data.name)
                setColours(colourOptions.filter(desc => desc.id === resp.data.color)[0])
                setUploadedFiles([{
                        id:resp.data.icon.split('/')[5],
                        name:resp.data.icon.split('/')[5],
                        // readableSize:resp.data.icon,
                        uploaded:true,
                        url:resp.data.icon,
                    }]
                )  
            })
        }
        
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            
           
        },
        margin: {
            '& label.Mui-focused': {
                color: '#BEBEBE',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#FFFF',
                color: '#FFFF'
            },
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.42)'
            },
            '& .MuiInputBase-input':{
                opacity: '0.42',
                borderBottom: '1px solid #000000',
                color: '#000'
            },
            '& .MuiFormLabel-root':{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '19px',
                letterSpacing: '0.15px',
                color: 'rgba(0, 0, 0, 0.54)',
            },
            margin: theme.spacing(1),
            width: '100%'
        },
        titleHeader:{
            fontFamily: 'SF Pro Text',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '42px',
            lineHeight: '50px',
            textAlign: 'center',
            color: '#1C68AD',
        }
        
    }));

    const defaultProps = {
        options: colourOptions,
        getOptionLabel: (option) => option.title,
        id:(option) => option.id
    };
    

    const dot = (color = '#ccc') => ({
        alignItems: 'center',
        display: 'flex',
      
        ':before': {
          backgroundColor: color,
          borderRadius: 10,
          content: '" "',
          display: 'block',
          marginRight: 8,
          height: 10,
          width: 10,
        },
      });
    
    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          const color = chroma(data.color);
          return {
            ...styles,
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? data.color
              : isFocused
              ? color.alpha(0.1).css()
              : null,
            color: isDisabled
              ? '#ccc'
              : isSelected
              ? chroma.contrast(color, 'white') > 2
                ? 'white'
                : 'black'
              : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',
      
            ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
            },
          };
        },
        input: styles => ({ ...styles, ...dot() }),
        placeholder: styles => ({ ...styles, ...dot() }),
        singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
      };

    const handleUpload = files => {
        const uploadedFile = files.map(file=>({
            file,
            id:uniqueId(),
            name:file.name,
            readableSize:filesize(file.size),
            
            progress:0,
            uploaded:false,
            error:false,
            url:null

        }))
        setUploadedFiles(uploadedFile)
    }

    const handleDelete = id => {
       
        const upload = uploadedFiles.filter(file =>file.id === id)
        // if(upload[0].url){
        //     del_attachment(id)
        //     .then(resp=> (
        //         viewPerId(props.match.params.id)
        //     ));
        // }else{
            setUploadedFiles(uploadedFiles.filter(file =>file.id !== id))
        // }
    }

    const handleForm=(e)=>{
        e.preventDefault();
        setLoading(true)
        // console.log(cpf.length)
        // debugger
      
     
        
        const data = new FormData();
        data.append('name',name)
        data.append('icon',uploadedFiles.length > 0 ? uploadedFiles[0].file : '')
        data.append('color',colours.id)
        if(props.match.params.action === 'edit'){
            data.append("_method", "put");

        }
   

        if(props.match.params.action === 'edit'){
            const id = props.match.params.id
            put_flow(id,data,{headers: {
                "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            },})
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })              


        }else{
            post_flow(data,{headers: {
                "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
            },})
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })              

        }
    }
    const classes = useStyles();
    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage estilo={{background: '#FFFFFF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '17px'}}>
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('')}
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ):('')}
                <div style={{display: loading === true ? ('none'):('block')}}>
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h2 className={classes.titleHeader} >{props.match.params.action === 'edit' ? "Edição de Fluxos" : props.match.params.action === 'view' ? "Visualização de Fluxos" : "Cadastro de Fluxos"}</h2>
                                    </div>
                                </div>
                            </div>{/* /.container-fluid */}
                        </section>
                    <Form onSubmit={handleForm}>
                        <div className="row">
                            <div className='col-md-6 offset-md-3'>
                                <TextField 
                                    className={classes.margin} 
                                    id="name" 
                                    name="name"
                                    label="Nome" 
                                    required={true}
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    type="text"
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div>
                            <div className="col-md-6 offset-md-3">
                                <Autocomplete
                                    {...defaultProps}
                                    value={colours}
                                    id="colors" 
                                    name="colors"
                                    onChange={(event, newValue) => {
                                        setColours(newValue);
                                    }}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    renderInput={(params) => <TextField {...params} className={classes.margin} label="Cor" required={true} />}
                                />
                            </div>
                        </div>
                        <br></br>
                        <div className="text-right">
                            <button type="button" className="btn-danger" style={{marginBottom:'10px', width: '150px', height:'36px', border: 'none', borderColor: '#bd2130', borderRadius: '4px', textTransform: 'uppercase', boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)'}} onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                 <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button onClick={(e) => {props.history.push(`/flow/${props.match.params.id}/edit`);e.preventDefault()}} className="btn-primary" style={{marginLeft:'10px', backgroundColor: '#007DFF', width: '150px', height:'36px', border: 'none', borderColor: '#bd2130', borderRadius: '4px', textTransform:'uppercase'}}>Editar</button>
                                    </ButtonContainner>
                                </div>
                            ) : (
                                <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button disabled={props.match.params.action === 'view' ? true : false} type="submit" className="btn-primary" style={{marginLeft:'10px', backgroundColor: '#007DFF', width: '150px', height:'36px', border: 'none', borderColor: '#bd2130', borderRadius: '4px', textTransform:'uppercase'}}>Cadastrar</button>                           
                                    </ButtonContainner>
                                </div>
                            )}
                        </div>
                        {/* <div className="row">
                            <div className="col-md-6">
                                <Input 
                                    label="Nome" 
                                    value={name} 
                                    onChange={(e)=>setName(e.target.value)} 
                                    icon="fas fa-pencil-alt" 
                                    name="name" 
                                    type="text" 
                                    placeholder="Digite o nome do fluxo" 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div>              
                            
                            <div className="col-md-6">
                                <label>Cor</label>
                                <Select 
                                    label="Single select"
                                    options={colourOptions}
                                    styles={colourStyles}
                                    onChange={(e)=>setColours(e)}
                                    value={colours}
                                    ref={selectColorRef}
                                    isDisabled={props.match.params.action === 'view' ? true : false}
    
                                />
                                {colours.length <= 0 && 
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={colours}
                                        onFocus={() => selectColorRef.current.focus()}
                                        required={true}
                                    />
                                }
                                {error ? (<p style={{color:'red'}}>{error.color}</p>):('')}                          
                            </div> */}
                        
                        
                            {/* <div className="col-md-12">
                                <label>Ícone</label>
                                <ContentUpload>
                                    <React.Fragment>
                                        <Upload disabled={props.match.params.action === 'view' ? (true):(false)} onUpload={handleUpload}/>
                                        {!!uploadedFiles.length && (
                                            <FileList action={props.match.params.action} toEdit={props.match.params.action === 'edit' ? (`/flow/${props.match.params.id}/edit`):('')} toCadastro="flow" files={uploadedFiles} onDelete={handleDelete}/>)}
                                    </React.Fragment>
                                </ContentUpload>
                            </div>   */}
                        {/* </div>
                    
                        <br></br>
                        <div className="text-right">
                            <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button type="button" onClick={(e) => {props.history.push(`/flow/${props.match.params.id}/edit`);e.preventDefault()}} className="btn btn-primary">Editar</button>
                                    </ButtonContainner>
                                </div>
                            ) : (
                                <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button disabled={props.match.params.action === 'view' ? true : false} type="submit" className="btn btn-primary">Salvar</button>                           
                                    </ButtonContainner>
                                </div>
                            
                            )}
                        </div>         */}
                    </Form>
                </div>   
            </StylePage>
            <Footer />
        </div>
    )
}
