import React from 'react'
import Menu from '../Menu/Menu'
import {connect} from 'react-redux'
import { PushMenu } from './style'
import RehagroFolha from '../Imagens/rehagro_folha_azul.png'
import Rehagro from '../Imagens/rehagro_logo_azul.png'
import RehagroBrancoCompleto from '../Imagens/rehagro_folha_branco.png'

function Sidebar(props) {  
    const style = {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '19px',
        letterSpacing: '0.15px',
        borderBottom: '1px solid #fff'
    }
    return (
        <React.Fragment>
            
            {/* Main Sidebar Container */}
            <aside className={`main-sidebar elevation-4 sidebar-dark-primary elevation-4`} style={{ background: '#1B3D70'}}>
                <PushMenu>
                    <a className="text-right nav-link push-sidebar" data-widget="pushmenu" href="#/"><i className="icon-circle fas fa-chevron-left" style={{color: 'rgb(0, 74, 52)'}}></i></a>
                </PushMenu>
                {/* Brand Logo */}
                <a href="#/" className='brand-link text-sm navbar-dark' style={{padding: 0, textAlign: 'center' , height: '49px', background: '#1B3D70', borderBottom: '1px solid #fff'}}> 
                    {/* <img src={RehagroBrancoCompleto} alt="" className="brand-image" width='400px' style={{ marginTop: '1%'}}/> */}
                    <span className="brand-text font-weight-light">
                        <img className="ml-2" alt="Logo Rehagro" src={RehagroBrancoCompleto} height='49px' width="180px"/>
                    </span>
                        
                </a>
                {/* Sidebar */}
                <div className="sidebar">
                    {/* Sidebar user panel (optional) */}
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex" style={{...style}} >
                        <div className="image" style={{paddingLeft: 0}}>
                            <img src={props.image ? `${props.image}` : 'https://tecnocoffeeapi.rehagro.com.br/storage/imagens/hiclipart.com.png'} className="img-circle elevation-4" alt="" />
                        </div>
                        <div className="info" style={{paddingLeft: '8px'}}>
                            <a href="#/" className="d-block" style={{color: 'white'}}>{props.name ? (props.name) : ('Consultor')}</a>
                        </div>
                    </div>
                    {/* Sidebar Menu */}
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column text-sm nav-compact nav-child-indent nav-flat nav-legacy" data-widget="treeview" role="menu" data-accordion="false">
                            <Menu/> 
                        </ul>
                    </nav>
                    {/* /.sidebar-menu */}
                </div>
                {/* /.sidebar */}
            </aside>

        </React.Fragment>
    )  
}
const mapStateToProps = state => {
    return{
        
        name:state.auth.user.name, 
        image:state.auth.user.foto_perfil
        
    }
  
};

export default connect(mapStateToProps)(Sidebar)
