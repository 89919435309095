import React from 'react'

export default function Footer() {
    return (

        <React.Fragment>
            <div className='card' style={{marginBottom: '0px'}}>
                <footer className="main-footer" style={{ background: '#1B3D70'}}>

                    <div className="float-right d-none d-sm-block">
                        <b>Version</b> 2.0
                    </div>
                    <strong style={{color: '#D1D1D1'}} >Copyright © 2020-2020 Rehagro TI</strong> Todos os direitos reservados

                </footer>
                {/* Control Sidebar */}
                <aside className="control-sidebar control-sidebar-dark">
                    {/* Control sidebar content goes here */}
                    <div id="sidebar-overlay"></div>
                </aside>
                {/* /.control-sidebar */}
            </div>
        </React.Fragment>

    )
}
