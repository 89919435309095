import React from 'react'
import "react-toggle/style.css"
import { ButtonAction } from '../../../Button/style'
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard'


export default function Evaluations(props) {
    const evaluation = props.evaluations || []
    const text = {
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#A1AFC3'
    }
    const button = {
        fontSize: '10px',
        lineHeight: '12px',
        color: '#1C68AD',
        marginRight: '10px', 
        marginTop: '3px', 
        background: 'rgba(28, 104, 173, 0.26)', 
        width: '80px', 
        padding: '0 0 0 2px', 
        height: '20px', 
        border: 'none'
    }
    return evaluation.map(desc=>(
        <div className="col-md-6 col-lg-4 mt-4" key={desc.id}>

            <RecipeReviewCard
                title={
                    <p>
                        <span>
                            <a href="#/" onClick={(e) => {props.history.push(`/farm/${desc.id_farm}/view`);e.preventDefault()}}>{`Fazenda: ${desc.farm}`}</a>
                        </span>

                    </p>
                }
                actionRoute={props.action}
                actions={
                //     props.action !== 'view' &&
                    <div className="text-center">
                        <ButtonAction>
                            
                            <a type="button" href={desc.report} target="_blank">
                                <i style={{color: '#1C68AD', width:'30px'}} className='fas fa-file-pdf'></i>Relatório
                            </a>
                            {/* <button type="button" onClick={(e) => props.history.push(`/question/${desc.id}/edit`)} style={{...text, ...button, color: '#0D832E', background: 'rgba(13, 131, 46, 0.26)', width: '70px'}}>Editar
                                <i style={{color: '#0D832E', width:'20px'}} className='fas fa-edit'></i>
                            </button>
                            <button type="button" onClick={()=>props.onDelete(desc.id)} style={{...text, ...button, color: '#F11717', background: 'rgba(241, 23, 23, 0.26)', width: '75px'}}>Excluir
                                <i style={{color: '#F11717', width:'20px'}} className='fas fa-trash-alt'></i>
                            </button> */}
                        </ButtonAction>

                    </div>
                
                }
                rel={false}
            >
                <p>
                    <span>
                        <a href="#/" onClick={(e) => {props.history.push(`/user/${desc.id_user}/view`);e.preventDefault()}}>{`Avaliador: ${desc.technical}`}</a>
                    </span>

                </p>
                <p>
                    <span>
                        <a href="#/" onClick={(e) => {props.history.push(`/flow/${desc.id_flow}/view`);e.preventDefault()}}>{`Fluxo: ${desc.flow}`}</a>
                    </span>

                </p>
                <p>
                    <span>
                        <a href="#/" onClick={(e) => {props.history.push(`/flow_pack/${desc.id_flow_pack}/view`);e.preventDefault()}}>{`Tópico: ${desc.flow_pack}`}</a>
                    </span>

                </p>
                <p style={{...text}}>
                    <span style={{...text, color:'black'}}>Data:</span>
                    {` ${desc.date}`}
                </p>
            </RecipeReviewCard>
        </div>
    ))
    
}
