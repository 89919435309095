import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/Header'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Sidebar from '../../../Sidebar/Sidebar'
import StylePage from '../../../Style Page/StylePage'
import Form from '../../../Forms/Form'
import TextArea from '../../../Forms/TextArea'
import Input from '../../../Forms/Input'
import NumberFormat from 'react-number-format'
import Alert from '../../../Alerts/Alert'
import Footer from '../../../Footer/Footer'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import Select from 'react-select'
import { Link, useHistory } from "react-router-dom"
import { post_question, get_view_question, put_question, get_resource } from '../../../Services/question'
import InputMaterial from '../../../Forms/InputMaterial';
import SelectMaterial from '../../../Forms/SelectMaterial';
import RadioMaterial from '../../../Forms/RadioMaterial';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonContainner } from '../../../Button/style';
import { uniqueId } from 'lodash'
import filesize from 'filesize'
import { ContentUpload } from '../../../Upload/styles'
import Upload from '../../../Upload/upload'
import FileList from '../../../FileList/FileList'





export default function Question(props) {

    let history = useHistory();
    const [ loading, setLoading ] = useState(false)
    const [ description, setDescription ] = useState('')
    const [ descriptionHelper, setDescriptionHelper ] = useState('')
    const [ title, setTitle ] = useState('')
    const [ action, setAction ] = useState('')
    const [ rightAnswer, setRightAnswer ] = useState("Não")
    const [ requiresDetailing, setRequiresDetailing ] = useState("Não")
    const [ requiresPhoto, setRequiresPhoto ] = useState("Não")
    const [ detailingOption, setDetailingOption ] = useState("Ambas")
    const [ requireDetailingPhoto, setRequireDetailingPhoto ] = useState("Ambas")
    const [ weight, setWeight ] = useState('')
    const [ helpDel, setHelpDel ] = useState([])
    const [ topics, setTopics ] = useState([])
    const [ idTopic, setIdTopic ] = useState([])
    const [ uploadedFiles, setUploadedFiles ] = useState([])
    const [ error, setError ] = useState([])
    const [ success, setSuccess ] = useState('')
    const [ alert, setAlert ] = useState('')
    const [ link, setLink ] = useState('')
    const selectTopicRef = useRef(null)

    useEffect(() => {
        getResource()
    }, [])

    useEffect(() => {
        viewPerId()
    }, [])
  
    function getResource(){
        get_resource()
        .then(resp => {
            setTopics(resp.data.topic.map(desc => ({
                value: parseInt(desc.id),
                title: desc.name
            })))
        })
    }

    const useStyles = makeStyles({
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
            color:'#FFFF'
            
        },
        icon: {
            borderRadius: '50%',
            width: 16,
            height: 16,
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
                color: '#FFFF'
            },
        },
        checkedIcon: {
            backgroundColor: '#137cbd',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 16,
                height: 16,
                backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: '#106ba3',
            },
        },
      });
      
    // Inspired by blueprintjs
    function StyledRadio(props) {
        const classes = useStyles();
        return (
            <Radio
                className={classes.root}
                disableRipple
                color="default"
                
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
                {...props}
            />
        );
      }

    function viewPerId(){
        props.match.params.action &&
        get_view_question(props.match.params.id)
        .then(resp => {
            setDescription(resp.data.question[0].description)
            setIdTopic({value: resp.data.question[0].id_topic, title: resp.data.question[0].topic})
            setWeight(resp.data.question[0].weight)
            setTitle(resp.data.question[0].title)
            setRequiresDetailing(resp.data.question[0].require_detailing === 1 ? 'Sim' : 'Não')
            setRequiresPhoto(resp.data.question[0].require_photo === 1 ? 'Sim' : 'Não')
            setRightAnswer(resp.data.question[0].right_answer === 1 ? 'Sim' : 'Não')
            setAction(resp.data.question[0].action)
            setUploadedFiles(resp.data.help.filter(desc => parseInt(desc.id_type_help) === 2).map(file => ({
                id:file.id,
                name:file.name,
                uploaded:true,
                url:file.link,
            })))
            let descriptionHelp = resp.data.help.filter(desc => parseInt(desc.id_type_help) === 1)
            setDescriptionHelper(descriptionHelp.length > 0 ? descriptionHelp[0].description : '')

            let videoHelp = resp.data.help.filter(desc => parseInt(desc.id_type_help) === 3)
            setLink(videoHelp.length > 0 ? videoHelp[0].link : '')
        })
    }

    const defaultProps = {
        options: topics,
        getOptionLabel: (option) => option.title,
        value:(option) => option.value
    };

    //Concatenando arquivos para upload
    const handleUpload = files => {
        const uploadedFile = files.map(file=>({
            file,
            id:uniqueId(),
            name:file.name,
            readableSize:filesize(file.size),
            
            progress:0,
            uploaded:false,
            error:false,
            url:null

        }))
        setUploadedFiles(uploadedFiles.concat(uploadedFile))
    }

    //Deleta arquivo
    const handleDelete = id => {
       
        const upload = uploadedFiles.filter(file =>file.id === id)
        if(upload[0].url){
            setHelpDel(helpDel.concat(id))
            setUploadedFiles(uploadedFiles.filter(file =>file.id !== id))
        }else{
            setUploadedFiles(uploadedFiles.filter(file =>file.id !== id))
        }
    }
  
    

    const handleForm=(e)=>{
        e.preventDefault();
        setLoading(true)
       
        const data = new FormData();
        let uploadedFile = uploadedFiles.filter(desc => !desc.url)

        for(let i =0; i<uploadedFile.length; i++){
            if(uploadedFile[i].file){
                data.append(`file${i}`,uploadedFile[i].file);
            }
        }
        // uploadedFiles.map(desc => {
        // })
        // dataHelp.append('imagesHelp', JSON.stringify(files))
        // console.log(dataHelp)
        // debugger
        data.append("description", description)
        data.append("descriptionHelp", descriptionHelper)
        data.append("idTopic", parseInt(idTopic.value))
        data.append("weight", weight)
        data.append("title", title)
        data.append("rightAnswer", rightAnswer === 'Sim' ? 1 : 0)
        data.append("requiresDetailing", requiresDetailing === 'Sim' ? 1 : 0)
        data.append("requiresPhoto", requiresPhoto === 'Sim' ? 1 : 0)
        data.append("action", action)
        data.append("requirePhotoOption", requiresPhoto === 'Sim' ? requireDetailingPhoto : '')
        data.append("requireDetailingOption", requiresDetailing === 'Sim' ? detailingOption : '')
        data.append("qtdFile", uploadedFile.length)
        data.append("helpDel", JSON.stringify(helpDel))
        data.append("link", link)
        if(props.match.params.action === 'edit'){
            data.append("_method", "put");

        }

        if(props.match.params.action === 'edit'){
            const id = props.match.params.id
            put_question(id,data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })              


        }else{
            post_question(data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })              

        }
    }

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage estilo={{background: '#FFFFFF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '17px'}}>
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('')}
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ):('')}
                <div style={{display: loading === true ? ('none'):('block')}}>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 style={{color: '#00ABFF', textAlign: 'center',fontFamily: 'SF Pro Text',fontStyle: 'normal',fontWeight: 'bold',fontSize: '42px',lineHeight: '50px',textAlign: 'center',color: '#1C68AD',}}>{props.match.params.action === 'edit' ? "Edição de Perguntas" : props.match.params.action === 'view' ? "Visualização de Perguntas" : "Cadastro de Perguntas"}</h2>
                                </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>
                    <Form onSubmit={handleForm}>
                        <div className="row">
                            <div className="col-md-4">
                                <InputMaterial 
                                    label="Título"
                                    id="titleQuestion"
                                    name="titleQuestion"
                                    value={title}
                                    onChange={(e)=>setTitle(e.target.value)} 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                            </div>
                            <div className="col-md-4">
                                <SelectMaterial 
                                    label="Tópico"
                                    id="topicQuestion"
                                    name="topicQuestion"
                                    value={idTopic}
                                    onChange={(event, newValue) => {
                                        setIdTopic(newValue);
                                    }}
                                    user={true}
                                    defaultProps={defaultProps}
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                            </div>
                            <div className="col-md-4">
                                <NumberFormat  
                                    label="Peso" 
                                    name="weight" 
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    thousandSeparator={"."} 
                                    decimalSeparator={","}
                                    placeholder=""
                                    customInput={InputMaterial}
                                    allowNegative={false}
                                    onChange={(e)=>setWeight(parseFloat(e.target.value))} 
                                    value={weight}
                                    disabled={props.match.params.action === 'view' ? (true):(false)}
                                    required={true} 
                                    isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue > 0 && floatValue <= 10;}}                         
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <TextArea 
                                    styleLabel={{
                                        fontFamily: 'Roboto',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        lineHeight: '19px',
                                        letterSpacing: '0.15px',
                                        color: 'rgba(0, 0, 0, 0.54)',
                                    }}
                                    style={{
                                        border: '1px solid #262D40',
                                        /* CAMPOS DIGITÁVEIS AZUL ESC */

                                        // boxShadow: 'inset 0px -1px 1px rgba(255, 255, 255, 0.15), inset 1px 1px 5px rgba(0, 0, 0, 0.4), inset -3px -2px 3px rgba(255, 255, 255, 0.15)',
                                        
                                    }}
                                    label="Descrição *" 
                                    value={description} 
                                    onChange={(e)=>setDescription(e.target.value)} 
                                    icon="fas fa-pencil-alt" 
                                    name="name" 
                                    type="text" 
                                    rows={6}
                                    placeholder="Digite a pergunta" 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div> 
                        </div>
                        <div className="row mt-4 ml-2">
                            <div className="col-md-4">
                                <RadioMaterial
                                    row 
                                    label="Resposta Correta"
                                    ariaLabel="rightAnswer" 
                                    name="rightAnswer" 
                                    value={rightAnswer}
                                    styleLabel={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}}  
                                    onChange={(e)=>setRightAnswer(e.target.value)}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                >
                                    <FormControlLabel value="Sim" control={<StyledRadio />} label="Sim" style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}}/>
                                    <FormControlLabel value="Não" control={<StyledRadio />} label="Não" style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}}/>
                                </RadioMaterial>
                            </div>
                            <div className="col-md-4">
                                <RadioMaterial
                                    row 
                                    label="Exige Detalhamento"
                                    row 
                                    aria-label="requiresDetailing" 
                                    name="requiresDetailing" 
                                    value={requiresDetailing}
                                    styleLabel={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}} 
                                    onChange={(e)=>setRequiresDetailing(e.target.value)}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                >
                                    <FormControlLabel value="Sim" control={<StyledRadio />} label="Sim" style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}}/>
                                    <FormControlLabel value="Não" control={<StyledRadio />} label="Não" style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}}/>
                                </RadioMaterial>
                            </div>
                            <div className="col-md-4">
                                <RadioMaterial
                                    row 
                                    label="Exige Fotos"
                                    row aria-label="requiresPhoto" 
                                    name="requiresPhoto" 
                                    value={requiresPhoto} 
                                    styleLabel={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}} 
                                    onChange={(e)=>setRequiresPhoto(e.target.value)}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                >
                                    <FormControlLabel value="Sim" control={<StyledRadio />} label="Sim" style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}}/>
                                    <FormControlLabel value="Não" control={<StyledRadio />} label="Não" style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}}/>
                                </RadioMaterial>
                            </div>
                        </div>
                        <div className="row mt-3 ml-2">
                            {requiresDetailing === 'Sim' && 
                            
                                <div className="col-md-12 mt-3">
                                    <RadioMaterial
                                        row 
                                        label="Exigir Detalhamento Para Respostas:"
                                        row aria-label="DetailingOption" 
                                        name="Detailing Option" 
                                        value={detailingOption} 
                                        styleLabel={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}} 
                                        onChange={(e)=>setDetailingOption(e.target.value)}
                                        disabled={props.match.params.action === 'view' ? true : false}
                                    >
                                        <FormControlLabel value="Sim" control={<StyledRadio />} label="Sim" style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}}/>
                                        <FormControlLabel value="Não" control={<StyledRadio />} label="Não" style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}}/>
                                        <FormControlLabel value="Ambas" control={<StyledRadio />} label="Ambas" style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}}/>
                                    </RadioMaterial>
                                </div>
                            }
                            {requiresPhoto === 'Sim' &&
                                <div className="col-md-12 mt-3">
                                    <RadioMaterial
                                        row 
                                        label="Exigir Fotos Para Respostas:"
                                        row aria-label="requireDetailingPhoto" 
                                        name="requireDetailingPhoto" 
                                        value={requireDetailingPhoto} 
                                        styleLabel={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}} 
                                        onChange={(e)=>setRequireDetailingPhoto(e.target.value)}
                                        disabled={props.match.params.action === 'view' ? true : false}
                                    >
                                        <FormControlLabel value="Sim" control={<StyledRadio />} label="Sim" style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}}/>
                                        <FormControlLabel value="Não" control={<StyledRadio />} label="Não" style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}}/>
                                        <FormControlLabel value="Ambas" control={<StyledRadio />} label="Ambas" style={{ fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', lineHeight: '19px', letterSpacing: '0.15px', color: 'rgba(0, 0, 0, 0.54)'}}/>
                                    </RadioMaterial>
                                </div>
                            }
                        </div>
                        {/* <hr></hr> */}
                        <br></br>
                        <div className= "card-header">
                            <h3 className="card-title">Registrar Ação para Pergunta</h3>      
                        </div> 
                        <div className="row mt-4 mb-4">
                            <div className="col-md-12">
                                <InputMaterial 
                                    label="Ação"
                                    id="nameAction"
                                    name="nameAction"
                                    value={action}
                                    onChange={(e)=>setAction(e.target.value)} 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                            </div>
                        </div>
                        {/* <hr></hr> */}
                        <br></br>
                        <div className= "card-header">
                            <h3 className="card-title">Helpers</h3>      
                        </div> 
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <label style={{
                                    color: 'rgba(0, 0, 0, 0.54)',
                                    padding: '0',
                                    fontSize: '1rem',
                                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                    fontWeight: 700,
                                    lineHeight: 1,
                                    letterSpacing: '0.00938em'
                                }}>Imagens</label>
                                <ContentUpload>
                                    <React.Fragment>
                                        <Upload 
                                            disabled={
                                                props.match.params.action === 'view' ? (true):(false)
                                            } 
                                            onUpload={handleUpload}
                                            accept="image/*"
                                            
                                        />

                                        {
                                            !!uploadedFiles.length && (
                                                <FileList 
                                                    action={props.match.params.action} 
                                                    toEdit={props.match.params.action === 'edit' ? (`/question/${props.match.params.id}/edit`):('')} 
                                                    toCadastro="question" 
                                                    files={uploadedFiles} 
                                                    onDelete={handleDelete}
                                                />
                                            )
                                        }
                                    </React.Fragment>
                                </ContentUpload>
                            </div>
                            
                       
                            
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <TextArea 
                                    styleLabel={{
                                        fontFamily: 'Roboto',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        lineHeight: '19px',
                                        letterSpacing: '0.15px',
                                        color: 'rgba(0, 0, 0, 0.54)',
                                    }}
                                    style={{
                                        border: '1px solid #262D40',
                                        /* CAMPOS DIGITÁVEIS AZUL ESC */

                                        // boxShadow: 'inset 0px -1px 1px rgba(255, 255, 255, 0.15), inset 1px 1px 5px rgba(0, 0, 0, 0.4), inset -3px -2px 3px rgba(255, 255, 255, 0.15)',
                                        
                                    }}
                                    label="Descrição Helper" 
                                    value={descriptionHelper} 
                                    onChange={(e)=>setDescriptionHelper(e.target.value)} 
                                    icon="fas fa-pencil-alt" 
                                    name="name" 
                                    type="text" 
                                    rows={6}
                                    placeholder="Digite a descrição para o Help" 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                            </div> 
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <InputMaterial 
                                    label="Vídeo"
                                    id="video"
                                    name="video"
                                    value={link}
                                    onChange={(e)=>setLink(e.target.value)} 
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                            </div>
                        </div>
                        <br></br>
                        <div className="text-right mt-4">
                            <button type="button" className="btn-danger" style={{marginBottom:'10px', width: '150px', height:'36px', border: 'none', borderColor: '#bd2130', borderRadius: '4px', textTransform: 'uppercase', boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)'}} onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                 <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button onClick={(e) => {props.history.push(`/question/${props.match.params.id}/edit`);e.preventDefault()}} className="btn-primary" style={{marginLeft:'10px', backgroundColor: '#007DFF', width: '150px', height:'36px', border: 'none', borderColor: '#bd2130', borderRadius: '4px', textTransform:'uppercase'}}>Editar</button>
                                    </ButtonContainner>
                                </div>
                            ) : (
                                <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button disabled={props.match.params.action === 'view' ? true : false} type="submit" className="btn-primary" style={{marginLeft:'10px', backgroundColor: '#007DFF', width: '150px', height:'36px', border: 'none', borderColor: '#bd2130', borderRadius: '4px', textTransform:'uppercase'}} >Salvar</button>                           
                                    </ButtonContainner>
                                </div>
                            )}
                        </div>
                       
                       
                    </Form>
                </div>   
            </StylePage>
            <Footer />
        </div>
    )
}
