import styled from 'styled-components';

export const Container = styled.div`
    a:hover{
        background: linear-gradient(270.37deg, rgba(17, 131, 237, 0.63) 19.87%, rgba(30, 88, 174, 0.08) 92.73%);
        color: '#FFF';
        p{
            color: white;
        }
        i{
            color: white;
        }
      
    }
    
    .selected{
        background: linear-gradient(270.37deg, rgba(17, 131, 237, 0.63) 19.87%, rgba(30, 88, 174, 0.08) 92.73%);
    }

`