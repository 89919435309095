import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './store/index'
import {Provider} from 'react-redux'
import jwt from 'jsonwebtoken'
import {URL} from './baseURL/baseURL'
import { information } from './Componentes/Services/auth'
import ReactLoading from 'react-loading'
import { Content } from './Componentes/LoadingStyle/StyleLoading'
import './Dependencies/dependencies'
import './Dependencies/jquery-loader'

const jwt_secret = 'MH611m1GBU4Jw8EtMNAd2YTX8LafphzXSNyCCF7H8ZXOS1E1SXrFX7tEcP7xKikv'

let token = localStorage.getItem('token')

if(token){
    jwt.verify(token, jwt_secret, function(err, decoded){
        if(err){
            console.log(err)
            if(err.name !== 'NotBeforeError'){
                localStorage.removeItem('token')
                token = null;
            }
            
        }else{
            if(decoded.iss !== URL+'/login'){
                localStorage.removeItem('token')
                token = null;
            }
        }
        
    });
}

const render = () => {
    ReactDOM.render(
        <React.Fragment>
            <Provider store={store}>
                <App />
            </Provider>
        </React.Fragment>
        
    , document.getElementById('root'));
}

const renderLoad = () => {
    ReactDOM.render(
        <Content>
            <ReactLoading type="spinningBubbles" color="green" height={300} width={300} />
        </Content>,
        document.getElementById('root')
    );
}
if(token){
    renderLoad()
    information()
   
    .then(res => {
        store.dispatch({type: "SET_LOGIN", payload:res.data})
    })
    .then(res => {

        render()
    })
}else{
    render();
}
