import './style.css';
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { sendEmailPassword } from '../../Services/user'
import ReactLoading from 'react-loading'
import { Content } from '../../LoadingStyle/StyleLoading'
import { connect } from 'react-redux'
import { Wrapper, LogoStyle, ContentWrapper, ContentRemember, Container, ButtonContainner, ButtonContainnerBack } from './styles'
import Alert from '../../Alerts/Alert'
import RehagroFolhaBranco from '../../Imagens/rehagro 2_branco 2.png';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import InputMaterial from '../../Forms/InputMaterial';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles((theme) => ({
    margin: {
        '& label.Mui-focused': {
            color: '#FFF',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#FFF',
            color: '#FFF'
        },
        
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #FFF'
        },
        '& .MuiInputBase-input':{
            opacity: '0.42',
            borderBottom: '1px solid #FFF',
            color: '#FFF'
        },
        '& .MuiFormLabel-root':{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '19px',
            letterSpacing: '0.15px',
            color: '#FFF',
            
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderColor: "#FFF"
        },
        ' & .MuiInput-underline:after': {
           
            borderBottom: '2px solid #FFF'
          
        },
    width: '100%',
        margin: theme.spacing(1),
    },
}));

export default function SendEmailRemember(props) {
    const classes = useStyles();
    const [ email, setEmail ] = useState('')
    const [ error, setError ] = useState('')
    const [ success, setSuccess ] = useState('')
    const [ loading, setLoading ] = useState(false)

    const handleForm = (e) => {
        e.preventDefault();
        setLoading(true)
        const data = { 
            email: email 
        }  
        sendEmailPassword(data)
        .then(resp => {
            setLoading(false)
            setError('')
            setSuccess(resp.data.message)
        })
        .catch(e => {
            setLoading(false)
            setSuccess('')
            setError(e.response.data.error)
        })
        
    }

    return (
        <Container>

                <Wrapper>
                    {loading === true && 
                        <Content>
                            <ReactLoading type="spinningBubbles" color="white" height={200} width={100} />
                        </Content>
                    }
                    <div style={{display: `${loading === true ? 'none' : 'block'}`}}>

                

                    {/* <div className="login-box">             */}
                    {/* /.login-logo */}
                    {/* <div className="card"> */}
                    <ContentWrapper>
                        <LogoStyle>
                            <img src={RehagroFolhaBranco} width="200px" alt="Logo Rehagro" className="d-block" />
                        </LogoStyle>
                        <div className="card-body login-card-body" style={{ backgroundColor: '#1b3d70' }}>
                            <h1 className='title-principal'>Bem vindo(a) ao Check Agro</h1>
                            <h2 className='title-principal subtitle'>Digite seu email de cadastro para receber o link de recuperação de senha</h2>
                        <i style={{ marginLeft: '125px'}} className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-2 mt-1"></i>
                        {/* <h5 className="login-box-msg">Entre com suas credenciais</h5> */}
                        {/* <p className="login-box-msg">Entre com suas credenciais</p> */}
                      
                        {error ? <Alert type="danger">{error}</Alert> : success ? <Alert type="success">{success}</Alert> : ''}  
                        <form onSubmit={handleForm}>
                            <div className="input-group" style={{display: `${success ? 'none' : ''}`}}>
                            <TextField
                                    className={classes.margin}
                                    label={email ? "Email" : ''}
                                    name="email" 
                                    placeholder="Escreva aqui seu e-mail"
                                    onChange={(e)=>setEmail(e.target.value)} 
                                    value={email}
                                    type="email" 
                                    InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <PersonOutlineOutlinedIcon   style={{fill: "#FFF"}}/>
                                        </InputAdornment>
                                    ),
                                    }}
                            />
                            {/* <InputMaterial  
                                label={!email ? "Escreva aqui seu e-mail" : ""}
                                name="email" 
                                placeholder="Escreva aqui seu e-mail"
                                onChange={(e)=>setEmail(e.target.value)} 
                                value={email}
                                type="email" 
                                required={true} 
                            />
                                {!email && <PermIdentityIcon className='icon-user' style={{fontSize:'25px'}}/>} */}
                            </div>
                            

                            

                            <div className="row mt-4" style={{justifyContent: 'flex-end'}}>
                                <div className="col-md-6 mt-3">
                                    <ButtonContainnerBack className='button'>
                                        <button style={{ borderRadius: '25px' }} type="button" onClick={() => props.history.push('/login')} className="btn btn-voltar btn-block">Voltar</button>
                                    </ButtonContainnerBack>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <ButtonContainner className='button'>
                                        <button style={{ borderRadius: '25px' }} type="submit" className="btn btn-login btn-block">Confirmar</button>
                                    </ButtonContainner>
                                </div>
                            </div>
                        </form>


                        </div>
                    {/* /.login-card-body */}
                    </ContentWrapper>
                {/* </div> */}
                {/* </div> */}

            </div>
                </Wrapper>

        </Container>

        // <React.Fragment>
        //     {loading === true && 
        //         <Content>
        //             <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
        //         </Content>
        //     }
        //     <div style={{display: `${loading === true ? 'none' : 'block'}`}}>

        //         <Wrapper>
        //             <div className="login-box">   
        //                 <div className="card">
        //                     <LogoStyle>
        //                         <img src="https://tecnocoffeeapi.rehagro.com.br/storage/imagens/rehagro.png" width="130px" alt="Logo Rehagro" className="d-block"  />
        //                     </LogoStyle>
        //                     <div className="card-body login-card-body">
        //                         {/* {error.errors ?( <p className="errors" style={{color: '#DC143C'}}>{error.errors}</p>):(success)} */}
        //                         <i style={{marginLeft:'125px'}} className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
        //                         <h5 className="login-box-msg">Recupere sua Senha</h5>
        //                         <p className="login-box-msg">Digite seu Email</p>
        //                         {/* {
        //                             error ? (
        //                                 <div>
        //                                     <Alert type="danger" hidden="true">
        //                                         {error}
        //                                     </Alert>
        //                                 </div>
        //                             ) :('')                                         
        //                         } */}
        //                         {error ? <Alert type="danger">{error}</Alert> : success ? <Alert type="success">{success}</Alert> : ''}      
        //                         <form onSubmit={handleForm}>
        //                             <div className="input-group mb-3" style={{display: `${success ? 'none' : ''}`}}>
        //                                 <input 
        //                                     onChange={(e)=>setEmail(e.target.value)} 
        //                                     value={email}
        //                                     type="email" 
        //                                     name="email" 
        //                                     className="form-control" 
        //                                     placeholder="Digite seu E-mail" 
        //                                 />
        //                                 <div className="input-group-append">
        //                                     <div className="input-group-text">
        //                                         <span className="fa fa-envelope" />
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                             <div className="row">
        //                                 <div className="col-md-12" style={{display: `${success ? 'none' : 'block'}`}}>
        //                                     <button style={{borderRadius: '25px'}} type="submit" className="btn btn-success btn-block">Confirmar</button>
        //                                 </div>                                       
        //                             </div>
        //                             <div className="row mt-2">
        //                                 <div className="col-md-12">
        //                                     <Link style={{borderRadius: '25px'}} type="button" to="/login" className="btn btn-secondary btn-block">Login</Link>
        //                                 </div>                                       
        //                             </div>                                      
        //                         </form>    
        //                     </div>
        //                 </div>
        //             </div>
        //         </Wrapper>
        //     </div>
        // </React.Fragment>
    )
}
