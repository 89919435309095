import './cabecalho.css';
import React from 'react'
import { Link } from 'react-router-dom'
import Input from '../Forms/Input'
import Select from '../Forms/Select'
import { ButtonContainner } from '../Button/style'
import { fontSize } from '@material-ui/system'

export default function Cabecalho(props) {

    const enterPressed = (event) => {
        var code = event.keyCode || event.which;
        if (code === 13) { //13 is the enter keycode
            props.action()
        }
    }

    const content = {
        background: '#FFFFFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '17px',
        paddingTop: '35px',
        marginLeft: 0
    }
    const registro = {
        background: '#007DFF',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: '24px',
        color: '#fff',
        fontSize: '18px',
    }

    return (

        <div className="row mt-3" style={{ ...content, alignItems: 'center'}}>
            <div className='col-md-3 col-sm-3' style={{ marginTop: '-6%' }}>
                {/* <section className="content-header">
                    <div className="container-fluid">
                        <div className="row" style={{ flexDirection: 'column', alignItems: 'center' }}> */}
                            <h2 className='title-header'>{props.title}</h2>
                            {
                                props.route &&
                                <ButtonContainner style={{textAlign: 'center'}}>
                                    <button type="button" onClick={(e) => { props.history.push(`${props.route}`); e.preventDefault() }} className="btn btn-primary" style={{ textTransform: 'uppercase' }}>Cadastrar</button>
                                </ButtonContainner>
                            }
                            {/* <Link to="/user" className="btn btn-primary">Cadastro</Link> */}
                        {/* </div> */}
                    {/*</div>*/}{/* /.container-fluid */}
                {/* </section> */}
            </div>

            <div className="col-md-6 col-sm-4 mt-1">
                <span className='pesquisar'>Pesquisa</span>
                <Input
                    onKeyPress={enterPressed}
                    value={props.valuePesquisa}
                    type="text"
                    onChange={props.changePesquisa}
                    className='input'
                    style={{ border: 'none', borderBottom: '2px solid #007DFF', borderRadius: '0' }}
                />
                <span className="fas fa-search icon-search"></span>
                
                <div className='buttons'>
                    <Link to={props.to} onClick={props.clear} className="btn btn-danger button-limpar">Limpar Busca</Link>
                    <Link to={props.to} onClick={props.action} className="btn btn-primary button-pesquisar">Pesquisar</Link>
                </div>
            </div>


            <div className="col-md-3 col-sm-3" style={{ textAlign: 'center', marginTop: '12px' }}>
                <label className="mt-2 ml-3" style={{ fontSize: '12px', margin: '-2.3%' }}>
                    <span style={{ textAlign: 'center' }}>Visualizar</span>
                    <Select style={{ ...registro }} value={parseInt(props.value)} onChange={props.onChange}>
                        <option value={9}>9</option>
                        <option value={24}>18</option>
                        <option value={49}>49</option>
                        <option value={99}>99</option>
                    </Select>
                    <span style={{ textAlign: 'center' }}>Registros</span>
                </label>
            </div>

            {/* <div className="row" style={{display: 'inline-block'}}>
                <div className="col-md-12">        
                    {props.buttonStatus} 
                </div>
            </div> */}
        </div>
    )
}

// export default function Cabecalho(props) {

//     const enterPressed = (event) => {
//         var code = event.keyCode || event.which;
//         if(code === 13) { //13 is the enter keycode
//             props.action()
//         } 
//     }
//     return (
//         <React.Fragment>
//             {props.children}
//             <div className="col-sm-12 col-md-2">
//                 <div className="dataTables_length" id="example1_length">
//                     <label> Visualizar  
//                         <select style={{marginLeft: '5px'}} value={parseInt(props.value)} onChange={props.onChange} name="example1_length" aria-controls="example1" className="custom-select custom-select-sm form-control form-control-sm">
//                             <option value={10}>10</option>
//                             <option value={25}>25</option>
//                             <option value={50}>50</option>
//                             <option value={100}>100</option>
//                         </select> Registros
//                     </label>
//                 </div>
//             </div>
//             <div className="col-sm-12 col-md-10">
//                 <div id="example1_filter" className="dataTables_filter">
//                     <label>

//                         {
//                             props.display === 'reimbursement' || props.display === 'visit' ? 
//                                 props.report === false && 
//                                     <React.Fragment>

//                                         <input 
//                                             onKeyPress={enterPressed} 
//                                             value={props.valuePesquisa} 
//                                             type="" 
//                                             onChange={props.changePesquisa} 
//                                             className="form-control form-control-sm" 
//                                             aria-controls="example1"
//                                         /> 
//                                         <span style={{marginLeft: '-9%'}} className="fas fa-search"></span> 
//                                     </React.Fragment>
//                             :
//                             <React.Fragment>

//                                 <input 
//                                     onKeyPress={enterPressed} 
//                                     value={props.valuePesquisa} 
//                                     type="" 
//                                     onChange={props.changePesquisa} 
//                                     className="form-control form-control-sm" 
//                                     aria-controls="example1"
//                                 /> 
//                                 <span style={{marginLeft: '-9%'}} className="fas fa-search"></span> 
//                             </React.Fragment>

//                         }

//                     </label>

//                     <Link to={props.to} onClick={props.action} style={{marginLeft:'25px'}} className="btn btn-outline-info btn-sm">Pesquisar</Link>
//                     <Link to={props.to} onClick={props.clear} style={{marginLeft:'10px', marginRight:'10px'}} className="btn btn-outline-danger btn-sm">Limpar Busca</Link>
//                     {props.buttonCsv}
//                 </div>
//             </div>
//             <div className="row" style={{display: 'inline-block'}}>
//                 <div className="col-md-12">        
//                     {props.buttonStatus} 
//                 </div>
//             </div>
//         </React.Fragment> 
//     )
// }
