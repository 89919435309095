import React from 'react'
import {connect} from 'react-redux'
import { useSelector } from  'react-redux'

function header(props) {

    const handleRemove = e => {
        e.preventDefault();
        localStorage.removeItem('token')
        props.logout()
        window.location.reload()
    }

    const style = {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '19px',
        letterSpacing: '0.15px',
    }
    
    return (
        <React.Fragment>

            <div style={{background: '#1B3D70'}}>

                <nav className={`main-header navbar navbar-expand text-sm`}>
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a style={{color: '#FFF'}} className="nav-link" data-widget="pushmenu" href="#/" role="button">
                                <i className="fa fa-bars" style={{fontSize: '20px'}} />
                            </a>
                        </li>
                    </ul>
                
                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">
                        {/* Messages Dropdown Menu */}
                        <li className="nav-item dropdown">
                            <a style={{color: '#FFF'}} className="nav-link" data-toggle="dropdown" href="#/">
                            <img src={props.image ? `${props.image}` : 'https://tecnocoffeeapi.rehagro.com.br/storage/imagens/hiclipart.com.png'} alt="logo_user" className="rounded-circle mr-2" style={{height:'30px',marginTop:'-8px'}} />
                                {/* <img src="https://tecnocoffeeapi.rehagro.com.br/storage/imagens/hiclipart.com.png" alt="Imagem" className="img-user img-circle elevation-3 mr-2" style={{height:'30px',marginTop:'-8px'}}/> */}
                                <span style={{...style, marginBottom: '50px'}}> {props.name ?(props.name):("Consultor")} </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                {/* <a href={`/profile/${props.id}`} className="dropdown-item">
                                    <i className="fas fa-cog" />
                                    <span>  Meus Dados</span>
                                </a> */}
                            <div className="dropdown-divider" />
                                <a href="#/" className="dropdown-item" onClick={handleRemove}>
                                    <i className="fa fa-power-off" />
                                    <span>  Sair</span>
                                </a>
                            </div>
                        </li>
                    </ul>

                </nav>
            </div>

        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return{
        loggedIn: state.auth.loggedIn,
        name:state.auth.user.name, 
        image:state.auth.user.foto_perfil,
        id: state.auth.user.id     
    }
  };
const mapDispacthToProps = dispatch => {
    return{
        logout:() => dispatch({type:'SET_LOGOUT'})    
    }
  };
  export default connect(mapStateToProps,mapDispacthToProps)(header)