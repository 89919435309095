const EvaluationReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Evaluations':
            return{...state, evaluations:actions.payload}

        case 'GetEvaluations_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetEvaluations_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_Evaluations':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaEvaluations':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaEvaluations':
            return{...state, pesquisa:actions.payload }

        case 'changePageEvaluations':
            return{...state, page:actions.payload}
   
        default:
            return state;
    }
}
export default EvaluationReducer;