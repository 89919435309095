import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import StylePage from '../../../Style Page/StylePage'
import Form from '../../../Forms/Form'
import Input from '../../../Forms/Input'
import Alert from '../../../Alerts/Alert'
import Footer from '../../../Footer/Footer'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import Select from 'react-select'
import { Link, useHistory } from "react-router-dom"
import { getFlows, getViewFlowPack, putFlowPack, postFlowPack } from '../../../Services/flowPack'
import makeAnimated from 'react-select/animated'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
  } from '@material-ui/core/styles';
import { ButtonContainner } from '../../../Button/style'

export default function FlowPack(props) {

    let history = useHistory();
    const selectFlowRef = useRef(null)
    const [ loading, setLoading ] = useState(false)
    const [ name, setName ] = useState('')
    const [ flows, setFlows ] = useState([])
    const [ flow, setFlow ] = useState([])
    const [ error, setError ] = useState([])
    const [ success, setSuccess ] = useState('')
    const [ alert, setAlert ] = useState('')
    const animatedComponents = makeAnimated();

    useEffect(() => {
        getAllFlows();
    }, [])

    useEffect(() => {
        viewPerId();
    }, [])

    function getAllFlows() {
        getFlows()
        .then(resp => {
            setFlows(
                resp.data.map(desc => ({
                    value: desc.id,
                    title: desc.name
                }))          
            )
        })
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            
           
        },
        margin: {
            '& label.Mui-focused': {
                color: '#BEBEBE',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#FFFF',
                color: '#FFFF'
            },
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.42)'
            },
            '& .MuiInputBase-input':{
                opacity: '0.42',
                borderBottom: '1px solid #000000',
                color: '#000'
            },
            '& .MuiFormLabel-root':{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '19px',
                letterSpacing: '0.15px',
                color: 'rgba(0, 0, 0, 0.54)',
            },
            margin: theme.spacing(1),
            width: '100%'
        },
        titleHeader: {
            fontFamily: 'SF Pro Text',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '42px',
            lineHeight: '50px',
            textAlign: 'center',
            color: '#1C68AD',
        }
    }));
    
    const defaultProps = {
        options: flows,
        getOptionLabel: (option) => option.title,
        id:(option) => option.id
    };

    function viewPerId() {
        props.match.params.action && (
            getViewFlowPack(props.match.params.id)
            .then(resp => {
                setName(resp.data.flow_pack[0].name)
                setFlow(resp.data.flows.map(desc => ({
                    value: desc.id,
                    title: desc.flow 
                })))
            })
        )
    }
   
    const handleForm=(e)=>{
        e.preventDefault();
        // console.log(flow)
        setLoading(true)
        
        let newFlow = [];
        flow.map(desc => newFlow.push(desc.value))
        const data = {
            name: name,
            flows: newFlow,
        }
        if(props.match.params.action === 'edit'){
            const id = props.match.params.id
            putFlowPack(id,data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })                 

        }else{
            postFlowPack(data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })                     

        }
    }
    const classes = useStyles();
    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage estilo={{background: '#FFFFFF', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', borderRadius: '17px'}}>
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('')}
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ):('')}
                <div style={{display: loading === true ? ('none'):('block')}}>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className={classes.titleHeader} >{props.match.params.action === 'edit' ? "Edição de Pacotes de Fluxo" : props.match.params.action === 'view' ? "Visualização de Pacotes de Fluxo" : "Cadastro de Pacotes de Fluxo"}</h2>
                                </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>
            
                    <Form onSubmit={handleForm}>
                        <div className="row">
                            <div className='col-md-6 offset-md-3'>
                                <TextField 
                                    label="Nome" 
                                    autoComplete="off"
                                    value={name} 
                                    onChange={e => setName(e.target.value)} 
                                    icon="fas fa-user" 
                                    name="name" 
                                    type="text" 
                                    placeholder="Digite o nome do pacote de fluxo" 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    className={classes.margin} 
                                />
                                {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div>
                           
                            <div className={`col-md-6 offset-md-3`}>
                                
                                <Autocomplete
                                    {...defaultProps}
                                    id="flow" 
                                    name="flows"
                                    value={flow}
                                    multiple
                                    ref={selectFlowRef}
                                    onChange={(event, newValue) => {
                                        let value = newValue
                                        for(let i = 0; i < value.length; i++){
                                            for(let j = i+1; j < value.length; j++ ){
                                                if(value[i].value === value[j].value){
                                                    value.splice(i)
                                                    value.splice(j)
                                                }
                                            }
                                        }

                                        setFlow(value);
                                    }}
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    renderInput={(params) => <TextField {...params}  className={classes.margin} label="Fluxos"  />}
                                />
                                {flow ? flow.length <= 0 ? (

                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={flow}
                                        onFocus={() => selectFlowRef.current.focus()}
                                        required={true}
                                    />
                                    ):''
                                    : flow == null ? (
                                    <input
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{
                                        opacity: 0,
                                        width: "100%",
                                        height: 0,
                                        position: "absolute"
                                    }}
                                    defaultValue={flow}
                                    onFocus={() => selectFlowRef.current.focus()}
                                    required={true}
                                    />
                                    ):''

                                    }
                               
                            </div>
                        </div>
                        <br></br>
                        <div className="text-right">
                            <button type="button" onClick={()=>history.goBack()} className="btn-danger" style={{marginBottom:'10px', width: '150px', height:'36px', border: 'none', borderColor: '#bd2130', borderRadius: '4px', textTransform: 'uppercase', boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)'}}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                 <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button onClick={(e) => {props.history.push(`/flow_pack/${props.match.params.id}/edit`);e.preventDefault()}} className="btn btn-primary" style={{marginLeft:'10px', backgroundColor: '#007DFF', width: '150px', height:'36px', border: 'none', borderColor: '#bd2130', borderRadius: '4px', textTransform:'uppercase'}}>Editar</button>
                                    </ButtonContainner>
                                </div>
                            ) : (
                                <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button disabled={props.match.params.action === 'view' ? true : false} type="submit" className="btn-primary" style={{marginLeft:'10px', backgroundColor: '#007DFF', width: '150px', height:'36px', border: 'none', borderColor: '#bd2130', borderRadius: '4px', textTransform:'uppercase'}}>Cadastrar</button>                           
                                    </ButtonContainner>
                                </div>
                            )}
                        </div>




                        {/* <div className="row">
                            <div className="col-md-6">
                                <Input 
                                    label="Nome" 
                                    autocomplete="off"
                                    value={name} 
                                    onChange={e => setName(e.target.value)} 
                                    icon="fas fa-user" 
                                    name="name" 
                                    type="text" 
                                    placeholder="Digite o nome do pacote de fluxo" 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div>              
                            
                        
                            <div className="col-md-6">
                                <label>Fluxos</label>      
                                    <Select
                                    
                                        value={flow}
                                        isMulti
                                        name="perfis"
                                        options={flows}
                                        components={animatedComponents}
                                        closeMenuOnSelect={false}
                                        className="basic-multi-select"
                                        ref={selectFlowRef}
                                        classNamePrefix="select"
                                        onChange={changeFlows}
                                        isDisabled={props.match.params.action === 'view' ? true : false}
                                    />
                                    {flow ? flow.length <= 0 ? (

                                            <input
                                                tabIndex={-1}
                                                autoComplete="off"
                                                style={{
                                                    opacity: 0,
                                                    width: "100%",
                                                    height: 0,
                                                    position: "absolute"
                                                }}
                                                defaultValue={flow}
                                                onFocus={() => selectFlowRef.current.focus()}
                                                required={true}
                                            />
                                        ):''
                                    : flow == null ? (
                                        <input
                                            tabIndex={-1}
                                            autoComplete="off"
                                            style={{
                                                opacity: 0,
                                                width: "100%",
                                                height: 0,
                                                position: "absolute"
                                            }}
                                            defaultValue={flow}
                                            onFocus={() => selectFlowRef.current.focus()}
                                            required={true}
                                        />
                                    ):''

                                    }
                               
                                {error ? (<p style={{color:'red'}}>{error.flows}</p>):('')}
                            </div>
                        </div>
                        <br></br>
                        <div className="text-right" style={{marginTop: '9%'}}>
                        <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                <Link to={`/flow_pack/${props.match.params.id}/edit`} className="btn btn-primary">Editar</Link>
                            ) : (
                                <button disabled={props.match.params.action === 'view' ? true : false} type="submit" className="btn btn-primary">Salvar</button>                           
                            )}                           
                        </div>         */}
                    </Form>
                </div>   
            </StylePage>
            <Footer />
        </div>
    )
}
