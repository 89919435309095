import styled from "styled-components";

export const Content = styled.div`
    
    
    overflow: hidden; /* para que não tenha rolagem se a imagem de fundo for maior que a tela */
    width: 100%;
    height: 100%;
    position: relative; 
    display: table;
    width: 100%;
    height: 84vh;
    background-position: 50% 50%;
    background-size: cover;
    
`;

export const TextContent = styled.div`

        
    margin-top:30px;
    font-size:25px;
    padding:20px;
    width:100%;
    color:#FFF;
    font-weight:700;
    font-family: 'Lato', sans-serif;
    
`;




