import './Dependencies/dependencies'
import './Dependencies/jquery-loader'
import React from 'react';
import { BrowserRouter ,Route, Switch } from "react-router-dom"
import Login from './Componentes/Pages/Auth/Login'
import LoginRouter from './Componentes/LoginRouter';
import User from './Componentes/Pages/Cadastros/User/User'
import Flow from './Componentes/Pages/Cadastros/Flow/Flow'
import GridFlow from './Componentes/Pages/Cadastros/Flow/GridFlow'
import Topic from './Componentes/Pages/Cadastros/Topic/Topic'
import GridTopic from './Componentes/Pages/Cadastros/Topic/GridTopic'
import Client from './Componentes/Pages/Cadastros/Client/Client'
import GridClient from './Componentes/Pages/Cadastros/Client/GridClient'
import Question from './Componentes/Pages/Cadastros/Question/Question'
import GridQuestion from './Componentes/Pages/Cadastros/Question/GridQuestion'
import Index from './Componentes/Pages/Index/Index'
import AuthRouter from './Componentes/AuthRouter'
import Action from './Componentes/Pages/Cadastros/Action/Action';
import GridAction from './Componentes/Pages/Cadastros/Action/GridAction';
import FlowPack from './Componentes/Pages/Cadastros/FlowPack/FlowPack';
import GridFlowPack from './Componentes/Pages/Cadastros/FlowPack/GridFlowPack';
import GridUser from './Componentes/Pages/Cadastros/User/GridUser';
import GridLine from './Componentes/Pages/Cadastros/Line/GridLine';
import Line from './Componentes/Pages/Cadastros/Line/Line';
import Producer from './Componentes/Pages/Cadastros/Producer/Producer';
import './App.css'
import GridProducer from './Componentes/Pages/Cadastros/Producer/GridProducer';
import Farm from './Componentes/Pages/Cadastros/Farm/Farm';
import GridFarm from './Componentes/Pages/Cadastros/Farm/GridFarm';
import PrivacyPolicy from './Componentes/Pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './Componentes/Pages/TermsAndConditions/TermsAndConditions';
import Suport from './Componentes/Pages/Suport/suport';
import RememberPassword from './Componentes/Pages/RememberPassword/RememberPassword';
import SendEmailRemember from './Componentes/Pages/RememberPassword/SendEmailRemember'
import GridEvaluation from './Componentes/Pages/Cadastros/Evaluation/GridEvaluation';

const Routes = () =>(
    <React.Fragment>      
        <BrowserRouter>
            <Switch>
                <LoginRouter exact path='/' component={Login}/>      
                <LoginRouter path="/login" component={Login} />
                <Route path="/send_email_remember" component={SendEmailRemember} /> 
                <Route path="/remember_password" component={RememberPassword} />
                <Route path="/politica_privacidade" component={PrivacyPolicy} />
                <Route path="/termos_condicoes" component={TermsAndConditions} />
                <Route path="/suporte" component={Suport} />

                <AuthRouter path="/index" component={Index} />

                <AuthRouter path="/user" exact component={User} />
                <AuthRouter path="/user/:id/:action" exact component={User} />
                <AuthRouter path="/users" component={GridUser} />

                <AuthRouter path="/flow" exact component={Flow} />
                <AuthRouter path="/flow/:id/:action" exact component={Flow} />
                <AuthRouter path="/flows" component={GridFlow} />

                <AuthRouter path="/topic" exact component={Topic} />
                <AuthRouter path="/topic/:id/:action" exact component={Topic} />
                <AuthRouter path="/topics" component={GridTopic} />

                <AuthRouter path="/client" exact component={Client} />
                <AuthRouter path="/customers" exact component={GridClient} />
                <AuthRouter path="/client/:id/:action" exact component={Client} />

                <AuthRouter path="/question" exact component={Question} />
                <AuthRouter path="/question/:id/:action" exact component={Question} />
                <AuthRouter path="/questions" component={GridQuestion} />

                {/* <AuthRouter path="/action" exact component={Action} />
                <AuthRouter path="/action/:id/:action" exact component={Action} />
                <AuthRouter path="/actions" component={GridAction} /> */}

                <AuthRouter path="/flow_pack" exact component={FlowPack} />
                <AuthRouter path="/flow_packs" exact component={GridFlowPack} />
                <AuthRouter path="/flow_pack/:id/:action" exact component={FlowPack} />

                <AuthRouter path="/lines" exact component={GridLine} />
                <AuthRouter path="/line" exact component={Line} />
                <AuthRouter path="/line/:id/:action" exact component={Line} />

                <AuthRouter path="/producers" exact component={GridProducer} />
                <AuthRouter path="/producer" exact component={Producer} />
                <AuthRouter path="/producer/:id/:action" exact component={Producer} />

                <AuthRouter path="/farms" exact component={GridFarm} />
                <AuthRouter path="/farm" exact component={Farm} />
                <AuthRouter path="/farm/:id/:action" exact component={Farm} />

                <AuthRouter path="/evaluations" exact component={GridEvaluation} />
              
            </Switch>      
        </BrowserRouter>  
    </React.Fragment>
);
export default Routes;