import React, { useState, useEffect } from 'react'
// import { Wrapper, LogoStyle } from './Style'
import Alert from '../../Alerts/Alert'
import { rememberPassword } from '../../Services/user'
import { Wrapper, LogoStyle, ContentWrapper, ContentRemember, Container, ButtonContainner } from './styles'
import { SettingsSystemDaydreamOutlined } from '@material-ui/icons'
import RehagroFolhaBranco from '../../Imagens/rehagro 2_branco 2.png';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import InputMaterial from '../../Forms/InputMaterial';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    margin: {
        '& label.Mui-focused': {
            color: '#FFF',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#FFF',
            color: '#FFF'
        },
        
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #FFF'
        },
        '& .MuiInputBase-input':{
            opacity: '0.42',
            borderBottom: '1px solid #FFF',
            color: '#FFF'
        },
        '& .MuiFormLabel-root':{
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '19px',
            letterSpacing: '0.15px',
            color: '#FFF',
            
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderColor: "#FFF"
        },
        ' & .MuiInput-underline:after': {
           
            borderBottom: '2px solid #FFF'
          
        },
        width: '100%',
        margin: theme.spacing(1),
    },
}));

export default function RememberPassword(props) {
    const classes = useStyles();
    const [ newPassword, setNewPassword ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ token, setToken ] = useState('')
    const [ confirmNewPassword, setConfirmNewPassword ] = useState('')
    const [ error, setError ] = useState('')
    const [ success, setSuccess ] = useState('')
    const [ errors, setErrors ] = useState([])
    const [type, setType] = useState('password')

    useEffect(() => {
        setAttrs()
        return () => {
            setAttrs()
        }
    }, [props.location.search])
    
    function setAttrs(){
        setEmail(props.location.search.split('&email')[1])
        setToken(props.location.search.split('&email')[0].replace('?token',''))
    }

    const changeType = (e) => {
        if(type === 'password'){
            setType('text')
        }else{
            setType('password')
        }
    }


    const handleForm = (e) => {
        e.preventDefault();
        if(newPassword !== confirmNewPassword){
            return setError('As Senhas não conferem !')
        }

        const data = { 
            password: newPassword, 
            password_confirmation: confirmNewPassword,
            email: email,
            token: token 
        } 
        rememberPassword(data)
        .then(resp => {
            setError('')
            setErrors([])
            setSuccess(resp.data.mensage)
            setTimeout(()=>{
                props.history.push('/login')
            },3000)
        }) 
        .catch(e => {
            setSuccess('')
            setError(e.response.data.error || '')
            setErrors(e.response.data.errors || [])
        })
  
    }

    return (
        <React.Fragment>
            <Container >

                <Wrapper>

                

                    {/* <div className="login-box">             */}
                    {/* /.login-logo */}
                    {/* <div className="card"> */}
                    <ContentWrapper>
                        <LogoStyle>
                            <img src={RehagroFolhaBranco} width="200px" alt="Logo Rehagro" className="d-block" />
                        </LogoStyle>
                        <div className="card-body login-card-body" style={{ backgroundColor: '#1b3d70' }}>
                            <h1 className='title-principal'>Bem vindo(a) ao Check Agro</h1>
                            <h2 className='title-principal subtitle'>REDEFINA SUA SENHA</h2>
                        {error.errors ? (<p className="errors" style={{ color: '#DC143C' }}>{error.errors}</p>) : (success)}
                        <i style={{ marginLeft: '125px'}} className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-2 mt-1"></i>
                        {/* <h5 className="login-box-msg">Entre com suas credenciais</h5> */}
                        {/* <p className="login-box-msg">Entre com suas credenciais</p> */}
                        {
                                error ? (
                                    <div>
                                        <Alert type="danger" hidden="true">
                                            {error}
                                        </Alert>
                                    </div>
                                ) :('')                                         
                            }
                            {
                                success ? (
                                    <div>
                                        <Alert type="success" hidden="true">
                                            {success}
                                        </Alert>
                                    </div>
                                ) :('')                                         
                            }
                            {
                                errors.password ? (
                                    <div>
                                        <Alert type="danger" hidden="true">
                                            {errors.password}
                                        </Alert>
                                    </div>
                                ) : ''
                            }
                        <form onSubmit={handleForm}>
                            
                            <div className="mb-3 input-group">
                                <TextField
                                    className={classes.margin}
                                    label={newPassword ? "Senha" : ""} 
                                    name="password" 
                                    placeholder="Escreva aqui sua senha"
                                    onChange={(e) => setNewPassword(e.target.value)} 
                                    value={newPassword}
                                    type={type} 
                                    InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <VpnKeyOutlinedIcon   style={{fill: "#FFF"}}/>
                                        </InputAdornment>
                                    ),
                                    }}
                                />
                                {/* <InputMaterial  
                                    label={!newPassword ? "Escreva aqui sua senha" : ""} 
                                    name="new_password" 
                                    placeholder=""
                                    type={type} 
                                    onChange={(e) => setNewPassword(e.target.value)} 
                                    value={newPassword}
                                    // required={true} 
                                /> */}
                                {/* <div  className="input-group-append"> */}
                                {/* <div className="input-group-text"> */}
                                {/* {!newPassword && <span className="icon-password fa fa-lock"/>} */}
                                <button  type="button" onClick={changeType} className="btn" style={{border: '0px solid transparent'}}><span className="icon-view-password fa fa-eye"/></button>
                                {/* </div> */}
                                {/* </div> */}
                            </div>

                            <div className="mb-3 input-group">
                                <TextField
                                    className={classes.margin}
                                    label={confirmNewPassword ? "Confirmar Senha" : ""} 
                                    name="password" 
                                    placeholder="Confirme aqui sua senha"
                                    onChange={(e) => setConfirmNewPassword(e.target.value)} 
                                    value={confirmNewPassword}
                                    type={type} 
                                    InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                        <VpnKeyOutlinedIcon   style={{fill: "#FFF"}}/>
                                        </InputAdornment>
                                    ),
                                    }}
                                />

                                {/* <InputMaterial  
                                    label={!confirmNewPassword ? "Confirme sua senha" : ""} 
                                    name="confirm_password" 
                                    placeholder=""
                                    type={type} 
                                    onChange={(e) => setConfirmNewPassword(e.target.value)} 
                                    value={confirmNewPassword}
                                    // required={true} 
                                /> */}
                                {/* <div  className="input-group-append"> */}
                                {/* <div className="input-group-text"> */}
                                {/* {!confirmNewPassword && <span className="icon-password fa fa-lock"/>} */}
                                <button  type="button" onClick={changeType} className="btn" style={{border: '0px solid transparent'}}><span className="icon-view-password fa fa-eye"/></button>
                                {/* </div> */}
                                {/* </div> */}
                            </div>

                            {/* <div className="social-auth-links mb-4 mt-3">
                                <ContentRemember>
                                    <p className="mb-1">
                                        <a href="/send_email_remember">Esqueceu sua senha? <span style={{color: '#2DBF79'}}>Clique Aqui</span></a>
                                    </p>
                                </ContentRemember>

                            </div> */}

                            <div className="row" style={{justifyContent: 'flex-end'}}>
                                {/* <div className="col-8">
                                            <div className="icheck-primary">
                                                <input type="checkbox" id="remember" />
                                                <label htmlFor="remember">
                                                Lembrar-me
                                                </label>
                                            </div>
                                        </div> */}

                                <div className="col-md-6">
                                    <ButtonContainner className='button'>
                                        <button style={{ borderRadius: '25px' }} type="submit" className="btn btn-login btn-block">Confirmar</button>
                                    </ButtonContainner>
                                </div>
                            </div>
                        </form>


                        </div>
                    {/* /.login-card-body */}
                    </ContentWrapper>
                {/* </div> */}
                {/* </div> */}

                </Wrapper>

            </Container>


            {/* /.login-box */ }
        </React.Fragment >





        // <React.Fragment>
        //     <Wrapper>
        //         <div className="login-box">            
        //             {/* /.login-logo */}
        //             <div className="card">
        //                     <LogoStyle>
        //                         <img src="https://tecnocoffeeapi.rehagro.com.br/storage/imagens/rehagro.png" width="130px" alt="Logo Rehagro" className="d-block"  />
        //                     </LogoStyle>
        //                 <div className="card-body login-card-body">
        //                     <i style={{marginLeft:'125px'}} className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
        //                     <h5 className="login-box-msg">Recupere sua Senha</h5>
        //                     <p className="login-box-msg">Digite sua Nova Senha</p>
        //                     {
        //                         error ? (
        //                             <div>
        //                                 <Alert type="danger" hidden="true">
        //                                     {error}
        //                                 </Alert>
        //                             </div>
        //                         ) :('')                                         
        //                     }
        //                     {
        //                         success ? (
        //                             <div>
        //                                 <Alert type="success" hidden="true">
        //                                     {success}
        //                                 </Alert>
        //                             </div>
        //                         ) :('')                                         
        //                     }
        //                     {
        //                         errors.password ? (
        //                             <div>
        //                                 <Alert type="danger" hidden="true">
        //                                     {errors.password}
        //                                 </Alert>
        //                             </div>
        //                         ) : ''
        //                     }
        //                     <form onSubmit={handleForm}>
        //                         <div className="input-group mb-3">
        //                             <input 
        //                                 onChange={(e) => setNewPassword(e.target.value)} 
        //                                 name="confirm_password" 
        //                                 className="form-control" 
        //                                 placeholder="Confirmar Nova Senha" 
        //                                 required={true}
        //                             />
        //                             <div className="input-group-append">
        //                                 <div className="input-group-text">
        //                                     <span className="fa fa-lock" />
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <div className="row">              
        //                             <div className="col-12">
        //                                 <button type="submit" className="btn btn-primary btn-block">Confirmar</button>
        //                             </div>                                       
        //                         </div>                                    
        //                     </form>              
        //                 </div>
        //             {/* /.login-card-body */}
        //             </div>
        //         </div>
        //     </Wrapper>
        //     {/* /.login-box */}
        // </React.Fragment>
    )
    
}
