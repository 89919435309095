import React, { useState, useEffect } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import Footer from '../../../Footer/Footer'
import StylePage from '../../../Style Page/StylePage'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { search, changePage, get, order, change_qtd_per_page, changePesquisa, clearPesquisa } from './Actions/actions'
import { pagination } from '../../../Pagination/paginations'
import Pagination from '../../../Table/Pagination'
import Cabecalho from '../../../Table/Cabecalho'
import Alert from '../../../Alerts/Alert'
import MyModal from '../../../Modal/MyModal'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import Farms from './Farms'
import { changeStatus, delFarm } from '../../../Services/farm'
import { ButtonContainner } from '../../../Button/style'


function GridFarm(props) {
    const [ loading, setLoading ] = useState(false);
    const [ modal, setModal ] = useState(false);
    const [ id, setId ] = useState('');
    const [ success, setSuccess ] = useState('');
    const [ error, setError ] = useState('');
    
   
    const getFarms = () => {
        props.get();
    }

    useEffect(() => {
        getFarms();
        return () => {
            getFarms()
        }
        
    },[])

    
    const changeStatusActive = (event) =>{
        const id = event.target.id
        let checked = event.target.checked === true ? 1 : 0
        const data = {
            active:checked,     
        }
        changeStatus(id, data)  
        .then(resp => getFarms())
        .catch(resp => getFarms())
    }
     
    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
        
    }

    const handleCloseExcluir = event => {
        setModal(false);
        setId('');
        
    }

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false)
        
        delFarm(id)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
        
        })
        .then(resp => (
            setTimeout(()=> {
                setSuccess('')
            },4000)

        ))
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (props.get()))
        // .then(resp => {setState({success:resp.data.success, error:''})})

    }

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage estilo={{ boxShadow: 'none', borderRadius: 'none'}}>

                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {success ? (
                    <Alert type="success">{success}</Alert>
                ):("")}
                {error ? (
                    <Alert type="danger">{error}</Alert>
                ):("")}
                <MyModal
                    show={modal}
                    onHide={handleCloseExcluir}
                    title="Excluir Fazenda"
                    content="Tem certeza que deseja excluir a fazenda ?"
                    cancel={handleCloseExcluir}
                    del={handleExcluir}     
                    to="#/"
                    type="danger"
                    textButton="Excluir"                   
                />
                <div style={{display: loading === true ? ('none'):('block'), background: '#C0CBDC'}}>
                <section className="content">
                        
                        <div className="card-body" style={{backgroundColor: '#C0CBDC'}}>
                            {props.farms.length > 0 || props.pesquisa ?
                                <Cabecalho to="#/" action={props.search} 
                                    clear={props.clearPesquisa} 
                                    valuePesquisa={props.pesquisa} 
                                    value={props.qtd_per_page} 
                                    onChange={props.change_qtd_per_page} 
                                    changePesquisa={props.changePesquisa}
                                    history={props.history}
                                    route='/farm'
                                    title='Registros de Fazendas'
                                />
                                :''
                            }
                            <div className="row mt-3" style={{marginLeft: 0}}>
                                {
                                    props.farms.length == 0 ? 
                                        <div 
                                            style={{
                                                color: '#FFFF', 
                                                textAlign: 'center', 
                                                width: '100%'
                                            }}
                                        >
                                            <span>{`${!props.pesquisa ? 'Não existem Fazendas cadastradas no sistema. Clique no botão Cadastro para inserir uma Fazenda no Sistema' : 'Nenhum registro encontrado na pesquisa'}`}</span>
                                        </div>
                                    :''

                                }
                                <Farms history={props.history} lines={props.lines} changeStatus={changeStatusActive} farms={props.farms} onDelete={onChangeModal} />
                            </div>
                            {props.farms.length > 0 &&
                                <div className="row">
                                    <div className="col-12 mt-4">
                                        <Pagination>{props.pagination(props.current_page,props.get,props.last_page,props.changePage)}</Pagination>
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                </div>
            </StylePage>
            
            
            <Footer />
        </div>
    )
}

const mapStateToProps = state => ({
    lines:state.farm.lines || [],
    farms:state.farm.farms.data || [],
    current_page:state.farm.farms.current_page,
    last_page:state.farm.farms.last_page,
    from:state.farm.farms.from,
    to:state.farm.farms.to,
    qtd_total:state.farm.farms.total,
    qtd_per_page:state.farm.qtd_per_page,
    pesquisa:state.farm.pesquisa,
    perfis:state.auth.user.perfis,
    id_role:state.auth.user.id_role,
    asc:state.farm.asc,
    th:state.farm.th  
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
        pagination, 
        get, 
        order, 
        change_qtd_per_page, 
        changePesquisa, 
        clearPesquisa,
        changePage,
        search 
    }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(GridFarm)
