import React from 'react';
import { Card, CardHeader, CardContent, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom'

const useStyles = makeStyles({
   root: {
      color: 'white',
      width: '400px',
      height: '334px',
      textAlign: 'center',
      boxShadow: '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
      borderRadius: '4px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
   },
   content: {
      boxSizing: 'border-box',
      textAlign: 'center',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '24px',
      lineHeight: '24px',
      letterSpacing: '0.4px',
      textTransform: 'uppercase',
      color: '#084A87',
   },

});

function CardIndex(props) {
   const classes = useStyles();
   return (
      <Card style={{margin: '30px 30px 0 10px'}}>
         <Link to={props.to}>
            <CardContent className={` mobile ${classes.root}`}>

               <Typography className={classes.content} style={{ color: '#0469FF' }} >
                  {props.icone ? <img src={props.icone}></img> : <i className={props.icon}></i>}
               </Typography>

               <Typography className={classes.content} style={{paddingTop: '30px'}}>
                  {props.subTitle}
               </Typography>

            </CardContent>
         </Link>
      </Card>

   );

}

export default CardIndex;