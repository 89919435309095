import React from 'react'
import { NavLink } from 'react-router-dom'
import { Container } from './Style'

export default function NavItem(props) {
    const style ={
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '30px',
        letterSpacing: '0.15px',
        color: 'black',
    }
    const space = props.icone ? {
        paddingLeft: '12.8px'
    } : '';

    return (
        <React.Fragment>
            <li className="nav-item" style={{...style}}>
                <Container style={{...space}} >

                    <NavLink to={props.to} className="nav-link" activeClassName='selected' style={{color: 'white'}}>
                        {props.icone ? <img src={props.icone}></img> : <i  className={`nav-icon ${props.icon}`}/>}
                        

                        {props.icone ?                         
                            <p style={{paddingLeft: '6px'}}>
                                {props.iconCircle?(
                                    <i className={`${props.iconCircle}` || ''}></i>
                                ):('')}
                                {props.item}
                                
                            </p> 
                            : <p>
                                {props.iconCircle?(
                                    <i className={`${props.iconCircle}` || ''}></i>
                                ):('')}
                                {props.item}
                                
                            </p>
                        }
                    </NavLink>
                </Container>
            </li>
        </React.Fragment>
    )
}
