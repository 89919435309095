import './style.css';
import React, { useState, useEffect } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import Footer from '../../../Footer/Footer'
import Table from '../../../Table/Table'
import Thead from '../../../Table/Thead'
import Tbody from '../../../Table/Tbody'
import Tfoot from '../../../Table/Tfoot'
import StylePage from '../../../Style Page/StylePage'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { search, changePage, get, order, change_qtd_per_page, changePesquisa, clearPesquisa } from './Actions/actions'
import { pagination } from '../../../Pagination/paginations'
import {Link} from 'react-router-dom'
import Pagination from '../../../Table/Pagination'
import Cabecalho from '../../../Table/Cabecalho'
import Alert from '../../../Alerts/Alert'
import MyModal from '../../../Modal/MyModal'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import Users from './Users'
import { del_user, changeStatus } from '../../../Services/user'
import { ButtonContainner } from '../../../Button/style'


function GridUser(props) {
    const [ loading, setLoading ] = useState(false);
    const [ modal, setModal ] = useState(false);
    const [ id, setId ] = useState('');
    const [ success, setSuccess ] = useState('');
    const [ error, setError ] = useState('');
    const name = 'user.name'; 
    const email = 'user.email'
    const role = 'role.description'
   
    const getUsers = () => {
        props.get();
    }

    useEffect(() => {
        getUsers()
        return () => {
            getUsers()
        }
    },[])
     
    const changeStatusActive = (event) =>{
        const id = event.target.id
        let checked = event.target.checked === true ? 1 : 0
        const data = {
            ativo:checked,     
        }
        changeStatus(id, data)  
        .then(resp => getUsers())
        .catch(resp => getUsers())
    }

    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
        
    }

    const handleCloseExcluir = event => {
        setModal(false);
        setId('');
        
    }

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false)
        
        del_user(id)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
        
        })
        .then(resp => (
            setTimeout(()=> {
                setSuccess('')
            },4000)

        ))
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (props.get()))
        // .then(resp => {setState({success:resp.data.success, error:''})})

    }

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage estilo={{ boxShadow: 'none', borderRadius: 'none'}}>

                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {success ? (
                    <Alert type="success">{success}</Alert>
                ):("")}
                {error ? (
                    <Alert type="danger">{error}</Alert>
                ):("")}
                <MyModal
                    show={modal}
                    onHide={handleCloseExcluir}
                    title="Excluir Usuário"
                    content="Tem certeza que deseja excluir o usuário ?"
                    cancel={handleCloseExcluir}
                    del={handleExcluir}     
                    to="#/"  
                    type="danger"
                    textButton="Excluir"                 
                />
                <div style={{display: loading === true ? ('none'):('block'), background: '#C0CBDC'}}>
                  
                <section className="content">
                
                    <div className="card-body" style={{backgroundColor: '#C0CBDC'}}>
                        {props.users.length > 0 || props.pesquisa ?
                            <Cabecalho to="#/" action={props.search} 
                                clear={props.clearPesquisa} 
                                valuePesquisa={props.pesquisa} 
                                value={props.qtd_per_page} 
                                onChange={props.change_qtd_per_page} 
                                changePesquisa={props.changePesquisa} 
                                history={props.history}
                                route='/user' 
                                title='Registro de Usuários' 
                            />
                            :''
                        }
                        <div className="row mt-3" style={{marginLeft: 0}}>
                            {
                                props.users.length == 0 ? 
                                    <div 
                                        style={{
                                            color: '#FFFF', 
                                            textAlign: 'center', 
                                            width: '100%'
                                        }}
                                    >
                                        <span>{`${!props.pesquisa ? 'Não existem Usuários cadastrados no sistema. Clique no botão Cadastro para inserir um Usuário no Sistema' : 'Nenhum registro encontrado na pesquisa'}`}</span>
                                    </div>
                                :''

                            }
                            <Users history={props.history} changeStatus={changeStatusActive} users={props.users} onDelete={onChangeModal} />
                        </div>
                        {props.users.length > 0 &&
                            <div className="row">
                                <div className="col-12 mt-4">
                                    <Pagination>{props.pagination(props.current_page,props.get,props.last_page,props.changePage)}</Pagination>
                                </div>
                            </div>
                        }
                    </div>
                </section>
    
                    {/* <Table 
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                    Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                                </div>
                            </div>
                        }
                            
                        cabecalho={
                            
                            <Cabecalho to="#/" action={props.search} clear={props.clearPesquisa} valuePesquisa={props.pesquisa} value={props.qtd_per_page} onChange={props.change_qtd_per_page} changePesquisa={props.changePesquisa} />
   
                        }
                        pagination={<Pagination>{props.pagination(props.current_page,props.get,props.last_page,props.changePage)}</Pagination>} title="Registros de Usuários"
                        button={
                           <Link to="/user" className="btn btn-primary">Cadastro</Link>
                            
                        }>
                        <Thead>  
                            <th>STATUS</th>  
                            <th>NOME<Link to="#/" style={{float:'right'}} onClick={()=>props.order(name)}><i className={`right fas fa-sort-amount-${props.th === 'user.name' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>EMAIL<Link to="#/" style={{float:'right'}} onClick={()=>props.order(email)}><i className={`right fas fa-sort-amount-${props.th === 'user.email' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>PERFIL<Link to="#/" style={{float:'right'}} onClick={()=>props.order(role)}><i className={`right fas fa-sort-amount-${props.th === 'role.description' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>AÇÕES</th>
                        </Thead>
                        <Tbody>
                            <Users changeStatus={changeStatusActive} users={props.users} onDelete={onChangeModal} />
                        </Tbody>
                        <Tfoot>
                            <th>STATUS</th>
                            <th>NOME</th>
                            <th>EMAIL</th>
                            <th>PERFIL</th>
                            <th>AÇÕES</th>
                        </Tfoot>
                    </Table>         */}
                </div>
            </StylePage>
            
            
            <Footer />
        </div>
    )
}

const mapStateToProps = state => ({
    users:state.user.users.data || [],
    current_page:state.user.users.current_page,
    last_page:state.user.users.last_page,
    from:state.user.users.from,
    to:state.user.users.to,
    qtd_total:state.user.users.total,
    qtd_per_page:state.user.qtd_per_page,
    pesquisa:state.user.pesquisa,
    id_role:state.auth.user.id_role,
    asc:state.user.asc,
    th:state.user.th  
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
        pagination, 
        get, 
        order, 
        change_qtd_per_page, 
        changePesquisa, 
        clearPesquisa,
        changePage,
        search
    }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(GridUser)
