import React from 'react'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import Card from '../../../Card/Card'
import { ButtonAction } from '../../../Button/style'
import MediaCard from '../../../MediaCard/MediaCard'
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard'

export default function Farms(props) {
    console.log(props.action)
    const farm = props.farms || []
    const line = props.lines || []
    const text = {
        fontFamily: 'SF Pro Text',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#A1AFC3'
    }
    const button = {
        fontSize: '10px',
        lineHeight: '12px',
        color: '#1C68AD',
        marginRight: '10px', 
        marginTop: '3px', 
        background: 'rgba(28, 104, 173, 0.26)', 
        width: '80px', 
        padding: '0 0 0 2px', 
        height: '20px', 
        border: 'none'
    }
    return farm.map(desc=>(
        <div key={desc.id}  className="col-md-5 col-lg-4 col-sm-4 mt-4" style={{border: 'none'}}>
            <RecipeReviewCard
                title={desc.name}
                actionRoute={props.action}
                actions={
                    props.action !== 'view' &&
                    <div className="text-center">
                        <ButtonAction>
                            <button type="button" onClick={(e) => props.history.push(`/farm/${desc.id}/view`)} style={{...text, ...button}}>Detalhes
                                <i style={{color: '#1C68AD', width:'20px'}} className='fas fa-eye'></i>
                            </button>
                            <button type="button" onClick={(e) => props.history.push(`/farm/${desc.id}/edit`)} style={{...text, ...button, color: '#0D832E', background: 'rgba(13, 131, 46, 0.26)', width: '70px'}}>Editar
                                <i style={{color: '#0D832E', width:'20px'}} className='fas fa-edit'></i>
                            </button>
                            <button type="button" onClick={()=>props.onDelete(desc.id)} style={{...text, ...button, color: '#F11717', background: 'rgba(241, 23, 23, 0.26)', width: '75px'}}>Excluir
                                <i style={{color: '#F11717', width:'20px'}} className='fas fa-trash-alt'></i>
                            </button>
                        </ButtonAction>

                    </div>
                
                }
                rel={line.length > 0 ?
                    line.filter(fil => parseInt(fil.id_farm) === parseInt(desc.id)).map((res,i) => (
                        <p>
                            <span>
                                <a href="#/" onClick={(e) => {props.history.push(`/line/${res.id_line}/view`);e.preventDefault()}}>{`Linha ${i+1}: ${res.name}`}</a>
                            </span>

                        </p>
                    )) : false
                }
            >
                <p style={{...text}}>
                    <span style={{...text, color: 'black'}}>Código:</span>
                    {` ${desc.code}`}
                </p>

                <p style={{...text}}>
                    <span style={{...text, color: 'black'}}>Telefone Fixo:</span>
                    {` ${desc.landLine}`}
                </p>

                <p style={{...text}}>
                    <span style={{...text, color: 'black'}}>Telefone Celular:</span>
                    {` ${desc.mobilePhone === null ? 'Não Possui' : desc.mobilePhone}`}
                </p>

                <p style={{...text}}>
                    <span style={{...text, color: 'black'}}>Conforme IN76/77 ? :</span>
                    {` ${desc.according_in7677 === 1 ? 'Sim' : 'Não'}`}
                </p>
                    
                <p>
                    <span style={{...text}}>
                        <a href="#/" onClick={(e) => {props.history.push(`/producer/${desc.id_producer}/view`);e.preventDefault()}} style={{ textDecoration: 'underline'}}>{`Produtor: ${desc.producer}`}</a>
                    </span>

                </p>
                    
            </RecipeReviewCard>
            {/* <Card 
                className="mt-3"
                height={props.action === 'view' ? '250px' : '380px'} 
                title={desc.name}
            >
                <div className="ml-3">
                    <p >{`Código: ${desc.code}`}</p>
                    <p >{`Telefone Fixo: ${desc.landLine}`}</p>
                    <p >{`Telefone Celular: ${desc.mobilePhone === null ? 'Não Possui' : desc.mobilePhone}`}</p>
                    <p >{`Telefone Fixo: ${desc.landLine}`}</p>
                    <p>{`Conforme IN76/77 ? : ${desc.according_in7677 === 1 ? 'Sim' : 'Não'}`}</p>
                    <p>
                        <span>
                            <a href="#/" onClick={(e) => {props.history.push(`/producer/${desc.id_producer}/view`);e.preventDefault()}}>{`Produtor: ${desc.producer}`}</a>
                        </span>

                    </p>
                    {line.filter(fil => parseInt(fil.id_farm) === parseInt(desc.id)).map((res,i) => (
                        <p>
                            <span>
                                <a href="#/" onClick={(e) => {props.history.push(`/line/${res.id_line}/view`);e.preventDefault()}}>{`Linha ${i+1}: ${res.name}`}</a>
                            </span>

                        </p>
                    ))}
                </div>
                {props.action !== 'view' &&
                    <div className="text-center" style={{position: 'absolute', width: '100%', top: '67%'}}>
                        <ButtonAction>
                            <button type="button" onClick={(e) => props.history.push(`/farm/${desc.id}/view`)} className="btn"><i style={{color: '#FFFF', width:'30px'}} className='fas fa-eye'></i></button>
                            <button type="button" onClick={(e) => props.history.push(`/farm/${desc.id}/edit`)} className="btn" ><i style={{color: '#FFFF', width:'30px'}} className='fas fa-edit'></i> </button>
                            <button type="button" className="btn" onClick={()=>props.onDelete(desc.id)} ><i style={{color: '#FFFF', width:'1px'}} className='fas fa-trash-alt'></i> </button>
                        </ButtonAction>

                    </div>
                }
            </Card> */}
        </div>

    ))
    
}
