import './styleIndex.css'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Content } from './styles'
import Sidebar from '../../Sidebar/Sidebar'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import CardIndex from './Card/CardIndex'
import IconProducer from '../../Imagens/producer_icon.png'
import IconFarm from '../../Imagens/farm_icon.png'

function Index(props) {
    // const explode = `${props.name}`.split(' ')
    // const name = explode[0]
    return (
        <React.Fragment>
            <Sidebar />
            <Header />
            <div className="content-wrapper" style={{ backgroundColor: '#C0CBDC' }}>
                <Content>

                    <section className="content" >
                        <div className="row index" style={{ marginTop: '4%', justifyContent: 'space-evenly', marginBottom: '4%'}} >
                            {/* <div className='row teste' style={{justifyContent: 'space-evenly'}}> */}
                            <CardIndex to='/users' icon='fa fa-users fa-6x' subTitle='Usuários'></CardIndex>
                            <CardIndex to='/customers' icon='fa fa-hands-helping fa-6x' subTitle='Parceiros'></CardIndex>
                            <CardIndex to='/producers' icone={IconProducer} subTitle='Produtores'></CardIndex>
                            <CardIndex to='/farms' icone={IconFarm} subTitle='Fazendas'></CardIndex>
                            <CardIndex to='#' icon='fa fa-cog fa-6x' subTitle='Configurações'></CardIndex>
                            <CardIndex to='/questions' icon='fa fa-question-circle fa-6x' subTitle='Perguntas'></CardIndex>
                            {/* </div> */}
                        </div>
                    </section>

                </Content>

                {/* <div className="row" style={{marginTop:'40px',marginLeft:'10px'}}>
                                <div className="col-md-12">
                                    <Link style={{marginBottom:'8px',fontSize:'15px', width:'250px', backgroundColor: '#004a34'}}  to="/visits" id="atividade" className="btn btn-success btn-lg" role="button">Lançamentos de Visitas</Link>

                                </div>
                                <div className="col-md-12">

                                    <Link style={{marginBottom:'8px',fontSize:'15px',width:'250px', backgroundColor: '#004a34'}} to="/refunds" className="btn btn-success btn-lg" role="button">Lançamentos de Reembolsos</Link>
                                </div>
                                <div className="col-md-12">

                                    <Link  to="/refunds_shared" style={{fontSize:'15px',width:'250px', backgroundColor: '#004a34'}} className="btn btn-success btn-lg" role="button">Lançamentos de Reembolsos Compartilhados</Link>
                                </div>

                            </div> */}
                {/* /.col */}

                {/* /.row */}


            </div>
            <Footer />
        </React.Fragment>
    )

}
const mapStateToProps = state => ({
    name: state.auth.user.name
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Index)
