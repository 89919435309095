import './style.css';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 'auto',
    minWidth: 180,
    background: 'white',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)',
    borderRadius: 0,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      {/* {props.actionDisabled &&
        <CardHeader 
            action={
                props.actionDisabled 
            // <IconButton aria-label="settings">
            //     <MoreVertIcon />
            // </IconButton> 
            }
            title={props.title}
            className='title'
        />
      } */}

        <CardHeader
          action={
            props.actionDisabled
            // <IconButton aria-label="settings">
            //     <MoreVertIcon />
            // </IconButton> 
          }
          title={props.title}
          className='title'
        />

      <div style={{ borderTop: '1px solid #A1AFC3', margin: 'auto', paddingBottom: '1%', width: '90%', height: '2%' }}></div>
      <CardContent style={{ width: '90%', background: 'white', margin: 'auto', borderLeft: '3px solid #1C68AD' }} >
        <Typography variant="body2" color="textSecondary" component="p" >
          {props.children}
        </Typography>
      </CardContent>

      <React.Fragment>

        <CardActions disableSpacing style={{ justifyContent: 'center' }}>
          {props.actionRoute !== 'view' && (props.actions)}
          {props.rel !== false &&

            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              style={{ color: 'black', outline: 'none'}}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="Mais Informações"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {props.rel}
          </CardContent>
        </Collapse>
      </React.Fragment>

    </Card>
  );
}
