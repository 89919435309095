import React from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
  } from '@material-ui/core/styles';

export default function SelectMaterial(props) {

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            
           
        },
        margin: {
            '& label.Mui-focused': {
                color: '#BEBEBE',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#FFFF',
                color: '#FFFF'
            },
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.42)'
            },
            '& .MuiInputBase-input':{
                opacity: '0.42',
                borderBottom: '1px solid #000000',
                color: '#000'
            },
            '& .MuiFormLabel-root':{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '19px',
                letterSpacing: '0.15px',
                color: 'rgba(0, 0, 0, 0.54)',
            },
            margin: theme.spacing(1),
            width: '100%'
        },
    }));

    const classes = useStyles();

    return (
        <Autocomplete
            {...props.defaultProps}
            value={props.value}
            id={props.id}
            name={props.name}
            onChange={props.onChange}
            autoComplete="new-password"
            // required={props.required}
            disabled={props.disabled}
            
            renderInput={params => {
                const inputProps = params.inputProps;
                props.user === true ? <TextField {...params } className={classes.margin} autoComplete="new-password" label={props.label} required={props.required}  /> 
                :
                inputProps.autoComplete = "new-password";
                return (
                  <TextField
                    {...params}
                    inputProps={inputProps}
                    className={classes.margin} autoComplete="new-password" label={props.label} required={props.required}
                  />
                );
              }
            }
            // renderInput={(params) => <TextField {...params } className={classes.margin} autoComplete="new-password" label={props.label} required={props.required}  />}
        />
    )
}
